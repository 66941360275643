import {combineReducers} from 'redux';
import SettingsReducer from './SettingsReducer'
import OrderReducer from './OrderReducer'
import ProductsReducer from './ProductsReducer';
import CustomersReducer from './CustomersReducer';
import {reducer as toastrReducer} from 'react-redux-toastr'

export default combineReducers({
    settings: SettingsReducer,
    order: OrderReducer,
    stock: ProductsReducer,
    toastr: toastrReducer,
    customers: CustomersReducer
})