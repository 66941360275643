import React, { useEffect } from 'react';
import {Header, Container, Col, CustomerList} from './styles'

import * as CustomersActions from '../../data/actions/CustomersActions'

import { connect, useDispatch } from 'react-redux';

import welcome_img from '../../assets/welcome.svg'
import lanchos_logo_mini_img from '../../assets/logo.svg'
import { baseURL } from '../../data/services/api';
import Icon from '../../assets/icon';


function Home(props) {
    const {customers = {}} = props;

    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(CustomersActions.list_customers())
    }, [dispatch])

    return (
        <React.Fragment>
            <Header>
                <h1>LanchOS</h1>

                <nav>
                    <ul>
                        <li>
                            <a href={'https://restaurante.lanchos.com'}><Icon icon={'lock'} size={24} /></a>
                        </li>
                    </ul>
                </nav>
            </Header>
            <Container className={'vertical-align center'}>
                <Col>
                    <img src={welcome_img} width={'50%'} alt={'LanchOS'} />
                </Col>
                <Col className={'full'}>
                    <h3>Vamos começar seu atendimento? Escolha um restaurante para começar!</h3>

                    <CustomerList>
                        {customers.data.map(customer => <li key={`customer-slug-${customer.slug}`}>
                            <a href={`/${customer.slug}`}>
                                {customer.logo && <img src={`${baseURL}/media/settings/${customer.logo}`} alt={customer.name} />}
                                {!customer.logo && <img src={lanchos_logo_mini_img} alt={customer.name} />}
                                {customer.name}

                                <div className={`status ${customer.is_opened ? 'open': ''}`}>{customer.is_opened ? 'Aberto' : 'Fechado'}</div>                            </a>
                        </li>)}
                    </CustomerList>
                </Col>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
  theme: state.settings.styles,
  customers: state.customers
});

export default connect(mapStateToProps)(Home);