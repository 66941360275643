import axios from 'axios'

export const baseURL = "https://api.lanchos.com";
// export const baseURL = "http://172.5.81.254:3333";
// export const baseURL = "http://10.1.0.3:3333"


export default axios.create({
    baseURL,
    timeout: 60000
})