import React from 'react'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
  } from "react-router-dom";
import CustomHashRouter from '../CustomHashRouter';
import Page404 from '../../views/Page404';
import Home from '../../views/Home';

function CustomRouter(props) {
    return (
        <Router>
            <Switch>
                <Route path={'/'} exact component={() => <Home />} />
                <Route path={'/404'} exact component={() => <Page404 />} />
                <Route path={'/:slug'} component={() => <CustomHashRouter />} />

                <Redirect from="/" to="/404" />
            </Switch>
        </Router>
    )
}


export default CustomRouter;