import api from './api';

export const OrderService = {
    confirmOrder: (order, store) => {
        return api.post(`/public/neworder/${store}`, {
            data: {
                ...order
            }
        });
    },

    checkStatus: (store, order_id) => {
        return api.get(`/public/orderstatus/${store}/${order_id}`);
    }
}
