import React, { useEffect, useContext, useState } from 'react';
import { Button, SecondaryButton,  MiniContainer, SecondaryText, Container, RouterLink, CloseWarning } from '../../assets/GlobalStyle';
import { Link, useHistory } from 'react-router-dom';
import CurrencyInput from 'react-currency-input';
import {PaymentMethod, MethodsWrapper} from './styles'
import { connect, useDispatch } from 'react-redux';
import * as OrderActions from '../../data/actions/OrderActions'
import * as SettingsActions from '../../data/actions/SettingsActions'
import { ThemeContext } from 'styled-components';
import utils from '../../utils';
import Loading from '../../Components/Loading';

function Payment(props) {
    const {slug = '', order_store = {}, payments_store = {}, delivery_store = []} = props;

    const payments = payments_store.data || [];

    const delivery = delivery_store.data || [];

    const order = order_store.data;

    const dispatch = useDispatch();

    const theme = useContext(ThemeContext);

    const [redirectOnSave, setRedirectOnSave ] = useState(false)

    const history = useHistory();

    useEffect(() => {
        if(order_store.isSaving) {
            setRedirectOnSave(true)
        }
        else{
            if(redirectOnSave && !order_store.error) {
                
            }
            else setRedirectOnSave(false)
        }
    }, [order_store.isSaving, order_store.error, redirectOnSave])

    useEffect(() => {
        console.log('order-id', order.id);
        if(order.id) history.push(`/status/${order.id}`);
    }, [history, order.id])

    useEffect(() => {
        dispatch(OrderActions.load_order(slug))
        dispatch(SettingsActions.paymentsList(slug))
        dispatch(SettingsActions.deliveryCharges(slug))
    }, [dispatch, slug])

    const handle_payment_category = pay => {
        let [cat] = payments.filter(payment => payment.id === pay.id)
        dispatch(OrderActions.set_order({
            store: slug,
            order: {
                ...order,
                payment_category: cat.id,
                payment_optional: null
            }
        }))
    }

    
    const [selectedPayment] = payments.filter(pay => order.payment_category && order.payment_category === pay.id);
    
    const handle_payment_optional = pay => {
        let [optional] = selectedPayment.optionals.filter(payment => payment.id === pay.id)
        dispatch(OrderActions.set_order({
            store: slug,
            order: {
                ...order,
                payment_optional: optional.id
            }
        }))
    }
    
    const [selectedCity] = order.id_city ? delivery.filter(deli => order.id_city && order.id_city === deli.id) : (delivery.length > 0 ? [delivery[0]] : [undefined]);
    const [selectedNeighborhood] = selectedCity && order.id_neighborhood ? selectedCity.neighborhoods.filter(deli => order.id_neighborhood && order.id_neighborhood === deli.id) : (selectedCity && selectedCity.neighborhoods.length > 0 ? [selectedCity.neighborhoods[0]] : [undefined]);
    
    const order_total = utils.getTotalFloat(order.items) + ((order.type === 'T' || (!order.type && theme.enable_delivery)) && selectedNeighborhood ? selectedNeighborhood.price : 0);

    const check_money_change = () => {
        if(selectedPayment && selectedPayment.money_change){
            if(order.type && order.type !== 'T') return true;
            else {
                return order.amount >= order_total
            }
        }
        else if(selectedPayment && order.payment_category && selectedPayment.optionals.length === 0) return true;
        else if(order.payment_category && order.payment_optional) return true;
        else return false
        
    }

    const confirm_order = () => {
        let tmp_order = {
            ...order
        }

        let type_options = 0;

        if(theme.enable_table)      type_options++;
        if(theme.enable_delivery)   type_options++;
        if(theme.enable_take_away)  type_options++;

        if((type_options === 1 && theme.enable_table) || 
            (order.type === 'M' && order.table_number !== undefined && order.table_number !== null)) tmp_order.type = 'M';
        else if(type_options === 1 && theme.enable_delivery) tmp_order.type = 'T';
        else if(type_options === 1 && theme.enable_take_away) tmp_order.type = 'L';

        dispatch(OrderActions.order_confirm(tmp_order, slug))
    }

    const isLoading = delivery_store.isLoading || payments_store.isLoading;

    const error = delivery_store.error || payments_store.error || null;

    return (
        <React.Fragment>
            {theme.is_opened !== undefined && !theme.is_opened && <CloseWarning>ESTAMOS FECHADOS</CloseWarning>}
            
            <Container className={'vertical-align center'}>
                {isLoading && <MiniContainer>
                    <Loading color={theme.SecondaryTextColor} />
                </MiniContainer>}
                
                {error && !isLoading && <MiniContainer>
                    <SecondaryText>Algo deu errado... :/</SecondaryText>
                    <SecondaryText>Não conseguimos encontrar as formas e pagamento utilizadas pelo estabelecimento...</SecondaryText>
                    <RouterLink>
                        {/* eslint-disable-next-line*/}
                        <a name="error_products">
                            <Button onClick={() => {
                                dispatch(OrderActions.load_order(slug))
                                dispatch(SettingsActions.deliveryCharges(slug))
                                dispatch(SettingsActions.paymentsList(slug))
                            }}>Tentar novamente</Button>
                        </a>
                    </RouterLink>
                </MiniContainer>}
                
                {!error && !isLoading && <MiniContainer>
                    <SecondaryText>Não falta muito! Agora, como deseja efetuar o pagamento?</SecondaryText>
                    <MethodsWrapper>
                        {payments.filter(pay => pay.status).map(pay => <PaymentMethod active={order.payment_category && order.payment_category === pay.id} key={`payment-${pay.id}`}>
                            <button onClick={() => handle_payment_category(pay)}>{pay.name}</button>
                        </PaymentMethod>)}
                    </MethodsWrapper>

                    {selectedPayment && selectedPayment.optionals && selectedPayment.optionals.length > 0 && <React.Fragment>
                        
                        <SecondaryText style={{marginTop: 30}}>Qual a bandeira do seu cartão?</SecondaryText>

                        <MethodsWrapper>
                            {selectedPayment.optionals.filter(pay => pay.status).map(pay => <PaymentMethod active={order.payment_optional && order.payment_optional === pay.id} key={`payment-${pay.id}`}>
                                <button onClick={() => handle_payment_optional(pay)}>{pay.name}</button>
                            </PaymentMethod>)}
                        </MethodsWrapper>
                    </React.Fragment>}

                    {(order.type === 'T' || order.type === undefined) && selectedPayment && selectedPayment.money_change && <React.Fragment>
                        <SecondaryText style={{marginTop: 30}}>Por favor, informe o valor do pagamento pra gente separar seu troco!</SecondaryText>

                        <CurrencyInput
                            type="text" 
                            style={{
                                width: '200px',
                                height: '45px',
                                backgroundColor: theme.backgroundColor,
                                color: theme.secondTextColor,
                                border: `2px solid ${theme.secondTextColor}`,
                                borderRadius: '50px',
                                textAlign: 'center',
                                alignSelf: 'center' 
                            }}
                            placeholder="R$ 0,00" 
                            decimalSeparator="," thousandSeparator="."
                            prefix="R$ "
                            value={order.amount}
                            onChange={(e, masked, value) => {
                                dispatch(OrderActions.set_order({
                                    store: slug,
                                    order: {
                                        ...order,
                                        amount: masked
                                    }
                                }))
                            }}
                            />
                        
                        {order.amount < order_total && <small><SecondaryText>Deve ser maior ou igual a R${utils.number_format(order_total,2,',','')}</SecondaryText></small>}
                    </React.Fragment>}

                    {selectedPayment && selectedPayment.id === '9fa4689c-180c-4bd3-b225-9fe000cc0705' && <React.Fragment>
                        <SecondaryText style={{marginTop: 30}}>Entre em contato com o estabelecimento para receber a chave pix, e para comprovar o pagamento!</SecondaryText>
                    </React.Fragment>}
                </MiniContainer>}

                {!error && !isLoading && <React.Fragment>
                    <RouterLink>
                        <Link to={'/pedido'}>
                            <SecondaryButton>REVISAR</SecondaryButton>
                        </Link>
                        {// eslint-disable-next-line
                        !order_store.isSaving && check_money_change() && <a>
                            <Button onClick={() => confirm_order()}>CONFIRMAR PEDIDO - R${utils.number_format(order_total,2,',','')}</Button>
                        </a>}

                        {// eslint-disable-next-line
                        !order_store.isSaving && !check_money_change() && <a>
                            <Button disabled>CONFIRMAR PEDIDO - R${utils.number_format(order_total,2,',','')}</Button>
                        </a>}
                        
                        {// eslint-disable-next-line
                        order_store.isSaving && <a>
                            <Button disabled>CONFIRMANDO PEDIDO...</Button>
                        </a>}

                        
                    </RouterLink>
                </React.Fragment>}
                    
                
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return ({
        order_store: state.order,
        payments_store: state.settings.payments,
        delivery_store: state.settings.delivery
    })
}

export default connect(mapStateToProps)(Payment);