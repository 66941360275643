import React from 'react';
import { Link } from 'react-router-dom';
import { Button, MiniContainer, SecondaryText, Container, RouterLink } from '../../assets/GlobalStyle';
import { baseURL } from '../../data/services/api';
import { connect } from 'react-redux';

function Introduction(props) {
    const {theme = {}} = props;

    return (
        <Container className={'vertical-align center'}>
            <MiniContainer style={{minHeight: '70%'}}>
                <SecondaryText>Bem vindo ao LanchOS!</SecondaryText>
                <SecondaryText>Vamos começar seu atendimento em {theme.name || '...'}!</SecondaryText>

                {theme.logo && <img src={`${baseURL}/media/settings/${theme.logo}`} className={'logo'} alt={'LanchOS'} />}
            </MiniContainer>
            
            <RouterLink>
                <Link to="/pedido">
                    <Button>COMEÇAR</Button>
                </Link>
            </RouterLink>
        </Container>
    )
}

const mapStateToProps = state => ({
  theme: state.settings.styles.data
});

export default connect(mapStateToProps)(Introduction);