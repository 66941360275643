import styled from 'styled-components';
import colors from '../../assets/colors';
import { breakPoints } from '../../assets/GlobalStyle';
import media from "styled-media-query";

export const Text = styled.p`
	color: ${props => props.theme ? props.theme.primaryTextColor : colors().primaryTextColor};
`;

export const Header = styled.header`
	background-color: #2a2e3b;
	width: 100%;
	height: 72px;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #CCC;
	padding: 0 20px;
	justify-content: space-between;

	h1{
		font-weight: 200;
		font-size: 22px;
	}

	nav {
		height: 100%;
		display: flex;
		align-items: center;

		ul{
			margin:0;
			padding: 0;

			li{
				list-style: none;

				a{
					text-decoration: none;
					color: #CCC;
				}
			}
		}
	}
`;

export const Container = styled.main`
	background-color: #2a2e3b;
	width: 100%;
	height: 100vh;
	display: flex;
	flex: 1;
	color: #CCC;
	flex-direction: column;
	overflow-y: auto;
	padding-bottom: 50px;

	${media.greaterThan(breakPoints.md)`
		flex-direction: row;
	`}
	
	h1{
		font-weight: 200;
		font-size: 22px;
	}

	h3{
		font-weight: 200;
		font-size: 16px;
	}
`;

export const Col = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;

	&.full {
		
	}

	${media.greaterThan(breakPoints.md)`
		width: 50%
	`}
`;

export const CustomerList = styled.ul`
	padding: 0;
	margin: 0;
	width: 100%;
	margin-top: 30px;

	margin-bottom: 100px;

	li{
		display: flex;
		margin-bottom: 10px;
		border-radius: 3px;
		background-color: #343b4a;
		width: 100%;
		height: 72px;
		align-items: center;

		a{
			text-decoration: none;
			color: #CCC;
			flex: 1;
			padding:15px;
			position: relative;

			img {
				width: 48px;
				height: 48px;
				border-radius: 50%;
				margin-right: 15px;
				background-color: #CCC;
			}

			.status{
				position: absolute;
				display: flex;
				right: 15px;
				top: 50%;
				height: 25px;
				transform: translateY(-50%);
				justify-content: flex-end;
				align-items: center;
				color: #CC0000; 
				
				&.open {
					color: #00CC00;
				}
			}
		}
	}
`;