const AsciiArt = () => {
	console.log(''+
'                   `....``                                          .:+syyyyyyyhhhyo/-              \n'+
'            ./shmNMMMMMMMMMmdys+-`                             ./ssso/-`          `-+ydh+.          \n'+
'         -smMMds+:-..``..-:/+shmMMmho:`                   `:ooo/-                      .+dd+`       \n'+
'      `+mMNy:`                   -/sdMMds:`           ./+++-                              .sNo`     \n'+
'    `oNMd/                           `:ohNMdo:..---:::-`                                    .yN/    \n'+
'   :mMm:                                  -ohNNh+-                                            :Ny   \n'+
'  +MMo                                        -odMNy/`                                         .mh  \n'+
' /MM/                                            `:ohNms:`                                      .Ny \n'+
'`NMo                                                  -+yddo:`                                   /M-\n'+
'+MN`                                                      `-+sso/-                                my\n'+
'dMy                                           `.                 .-.`                             sN\n'+
'NM/                                           od                                                  +M\n'+
'MM:                                           NM:         `y:                                     oN\n'+
'NM:                               yo         /MMy        .mMo                                     dy\n'+
'yMs                               dMo        hMMN       -NMMy                                    :M.\n'+
'.MN`                              dMMo      `NMMM/     -NMMMy         :o                        `mo \n'+
' +Md                              dMMM/     +MMMMh    :NMMMMs      `omM+                       `dy  \n'+
'  +Md`                   s:       dMMMM:    :MMMMs    mMMMMMy    .sNMMh                       `ds   \n'+
'   -mN/                  :Md:     sMMMM+     yMMm`    oMMMMd.   oMMMMN`                      :m/    \n'+
'     +md:                 yMMd:    +NMm-:/+oosNMyssoo++mMm+`    dMMMN:                     .yy`     \n'+
'       /dd/`              `NMMM.-/oyddhs+/:-.`           `.--.` :so:`                    -ys.       \n'+
'         .ody:             .dMNds+-`                                                  .+s+`         \n'+
'            `/ss/`        sNs-                                                    ./+o/`            \n'+
'                ./+/:`   oM.                                              `.--:://:`                \n'+
'                     .-.`yN                                            ``.``                        \n'+
'                         +M.                                                                        \n'+
'                         :M-                                         -                              \n'+
'                         .M/                                         +                              \n'+
'                          M+                                         +.                             \n'+
'                          No                                         :o                             \n'+
'                          N+                                         .d                             \n'+
'                          M:                                          M`                            \n'+
'                         .M`                                          N-                            \n'+
'                         -d              `.-::////////:-.`            m/                            \n'+
'                         :s      -/+syyhhysso+++//////++oossoo+/.     m+                            \n'+
'                         -+ -:/++/-.                            .-.`  ms                            \n'+
'                         `s.                                          Ns                            \n'+
'                          .:.                                       `yN.                            \n'+
'                             ``                              .-:/oyddo`                             \n'+
'                                    `.-:://++ooossyyyyhhhhhhhyso+:.                                 ');
}


export default AsciiArt;