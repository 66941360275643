import styled from "styled-components";
import { themeColor } from "../../assets/GlobalStyle";

export const Container = styled.li`
    width: 100%;
    padding: 10px 0;
    color: ${props => themeColor(props, 'secondTextColor')};
    border-bottom: 0.1px solid ${props => themeColor(props, 'secondTextColor')};
    display: flex;
    flex-direction: row;

    &:nth-last-child(1), &:only-child{
        border-bottom: none
    }

    div{
        flex-direction: column;
        flex: 1;
        box-sizing: border-box;
        
        h2 {
            /* font-weight: normal; */
            font-size: 16px;
            margin: 0;
        }

        p{ 
            font-size: 14px;
            margin: 0;
         }
    }

    .actions {
        flex-direction: column;
        width: 100px;
        height: 64px;
        flex: inherit;
        text-align: right;

        button {
            width: 40px;
            height: 40px;
            background-color: ${props => themeColor(props, 'accentColor')};
            border:none;
            box-shadow: none;

            background-color: transparent;

            svg {
                color: ${props => themeColor(props, 'accentColor')};
            }
        }

        p{ 
            font-weight: bold;
            font-size: 16px;
            align-self: flex-end;
        }
    }


    .thumb {
        width: 64px;
        height: 64px;
        box-sizing: border-box;
        border: 2px solid ${props => themeColor(props, 'secondTextColor')};
        border-radius: 50px;
        /* background-color: ${props => themeColor(props, 'detailTextColor')}; */
        margin-right: 15px;
        flex: inherit;
    }
`