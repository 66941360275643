import React from 'react';


import { Provider } from 'react-redux';
import rootReducer from './data/reducers';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import CustomRouter from './Components/CustomRouter';
import ReduxToastr from 'react-redux-toastr'

import SettingsSaga from './data/sagas/SettingsSaga'
import ProductsSaga from './data/sagas/ProductsSaga'
import OrderSaga from './data/sagas/OrderSaga'
import CustomersSaga from './data/sagas/CustomersSaga'
import { useEffect } from 'react';
import AsciiArt from './assets/AsciiArt';


const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(SettingsSaga);
sagaMiddleware.run(ProductsSaga);
sagaMiddleware.run(OrderSaga);
sagaMiddleware.run(CustomersSaga);



function App() {
	const appHeight = () => {
		document.querySelector('body').style.height = `${window.innerHeight}px`;
	}

	useEffect(() => {
		window.addEventListener('resize', appHeight)
		appHeight();

		// AsciiArt()
	}, [])

	return (
		<Provider store={store}>
			<CustomRouter />

			<ReduxToastr
			timeOut={4000}
			newestOnTop={false}
			transitionIn="fadeIn"
			transitionOut="fadeOut"
			closeOnToastrClick/>
		</Provider>
	);
}


export default App;
