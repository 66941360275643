import styled from 'styled-components';
import { themeColor, breakPoints } from '../../assets/GlobalStyle'
import media from "styled-media-query";

export const TextInput = styled.input`
    width: 80%;
    height: 45px;
    background-color: ${props => themeColor(props, 'backgroundColor')};
    color: ${props => themeColor(props, 'secondTextColor')};
    border: 2px solid ${props => themeColor(props, 'secondTextColor')};
    border-radius: 50px;
    text-align: center;

    ${media.greaterThan(breakPoints.xs)`
        width: 50%;
    `}
`;
