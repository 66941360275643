import React from 'react'
import './styles.scss';

export default function Container(props) {
    const {style = {}} = props;
    return (
        <div className={'container vertical-center'} style={style}>
            {props.children}
        </div>
    )
}
