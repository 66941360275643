import React, { useEffect } from 'react';
import Container from '../../Components/Container';
import Icon from '../../assets/icon';

export default function Splash(props) {
    const {slug = ''} = props;

    useEffect(() => {
        setTimeout(() => {
            document.location = `/${slug}#/inicio`
        }, 500);
    }, [slug])

    return (
        <Container style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Icon icon={'lanchos'} size={300} />
        </Container>
    )
}
