import React, { useContext } from 'react';
import {Container} from './styles'
import utils from '../../utils';
import { baseURL } from '../../data/services/api';
import { ThemeContext } from 'styled-components';
import Icon from '../../assets/icon';

export default function ProductItem(props) {
    const {
        product = {
            name: 'Produto',
            thumb: null,
            price: 0.00,
            ingredients: 'Teste ingredientes',
            id_category: 0,
            id: 0
        },
        onSelect = () => {}
    } = props;

    const theme = useContext(ThemeContext);

    return (
        <Container onClick={() => onSelect(product)}>
            <img className={'thumb'} alt='LanchOS' src={product.thumb ? `${baseURL}/media/products/${product.thumb}` : `${baseURL}/media/settings/${theme.logo}`} />
            <div>
                <h2>{product.name}</h2>
                <p>{product.ingredients}</p>
            </div>

            <div className={'actions'}>
                <button><Icon icon={'cart'} size={30} /></button>
                <p>R$ {utils.number_format(product.price,2,',','')}</p>
            </div>
        </Container>
    )
}