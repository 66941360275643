import styled from "styled-components";
import media from 'styled-media-query'
import { breakPoints, themeColor } from "../../assets/GlobalStyle";

export const OrderContainer = styled.div`
    display: flex;
    position: fixed;
    background-color: ${props => themeColor(props, 'primaryColor')};
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    z-index: 10;
    box-sizing: border-box;
    flex-direction: column;

    a {
        text-decoration: none;
    }

    img { margin-bottom: 15px; }

    p { color: ${props => themeColor(props, 'primaryTextColor')} }

    &.no-order{
        display: none;
    }

    &.extended{
        height: 100%;
    }

    ${media.greaterThan(breakPoints.lg)`
        height: 100%;
        width: 30%;
        right: 0;
        left: auto;
        /* max-width: 300px; */

        &.no-order{
            display:flex;
            justify-content: center;
            align-items: center;
        }
    `}
    
`;

export const OrderResume = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: ${props => themeColor(props, 'primaryTextColor')};

    ${media.greaterThan(breakPoints.lg)`
        display: none;
    `}
`

export const OrderList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 20px;
    padding-bottom: 0;
    width: 100%;
    flex: 1;
    overflow-y: auto;
    display: none;
    flex-direction: column;

    &.extended{
        display: flex;
    }

    ${media.greaterThan(breakPoints.lg)`
        display: flex;
    `}
`

export const OrderListItem = styled.li`
    width: 100%;
    padding: 10px 0;
    color: ${props => themeColor(props, 'primaryTextColor')};
    border-bottom: 0.1px solid ${props => themeColor(props, 'primaryTextColor')};
    display: flex;
    flex-direction: row;

    &:nth-last-child(1), &:only-child{
        border-bottom: none
    }

    div{
        flex-direction: column;
        flex: 1;
        box-sizing: border-box;
        
        h2 {
            /* font-weight: normal; */
            font-size: 16px;
            margin: 0;
        }

        p{ 
            font-size: 14px;
            margin: 0;
         }
    }

    .actions {
        flex-direction: column;
        width: 100px;
        flex: inherit;
        text-align: right;

        button {
            background-color: ${props => themeColor(props, 'primaryTextColor')};
            border:none;
            box-shadow: none;

            background-color: transparent;

            svg {
                color: ${props => themeColor(props, 'primaryTextColor')};
            }
        }
    }


    .thumb {
        width: 48px;
        height: 48px;
        box-sizing: border-box;
        border-radius: 50px;
        background-color: ${props => themeColor(props, 'backgroundColor')};
        margin-right: 15px;
        flex: inherit;
    }
`

export const OrderConfirm = styled.button`
    width: 100%;
    height: 60px;
    border: none;
    background-color: ${props => themeColor(props, 'accentColor')};
    color: ${props => themeColor(props, 'accentTextColor')};
    display: none;

    &.extended {
        display: block;
    }

    ${media.greaterThan(breakPoints.lg)`
        display: block;
    `}
`

export const ProductsContainer = styled.section`
    width: 100%;
    height: 100%;
    background-color: ${props => themeColor(props, 'backgroundColor')};
    padding: 20px;
    overflow-y: auto;
    padding-bottom: 30px;

    ${media.greaterThan(breakPoints.lg)`
        width: ${props => props.isLoading ? '100%' : '70%'};
        padding-bottom: 0;
    `}
`;

export const Category = styled.h1`
    color: ${props => themeColor(props, 'accentColor')};
    font-size: 20px;
    font-weight: normal;
`

export const ProductsList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 30px;
`;

export const ErrorWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p{ 
        color: ${props => themeColor(props, 'secondaryTextColor')}
    }
`;