import styled from 'styled-components';
import { themeColor, breakPoints } from '../../assets/GlobalStyle'
import media from "styled-media-query";

export const TextInput = styled.textarea`
    width: 80%;
    height: auto;
    min-height: 150px;
    background-color: ${props => themeColor(props, 'backgroundColor')};
    color: ${props => themeColor(props, 'secondTextColor')};
    border: 2px solid ${props => themeColor(props, 'secondTextColor')};
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px;
    /* border: none; */

    ${media.greaterThan(breakPoints.xs)`
        width: 50%;
    `}
`;
