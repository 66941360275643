import * as CustomersActions from '../actions/CustomersActions';
import {CustomersService} from '../services/CustomersService';

import { all, put, takeLatest } from 'redux-saga/effects';

//INDEX
function* list(action){
    let data = null;
    let error = null;
    yield CustomersService.list(action.filter).then(
        result => {
            data = result.data.data
        }
    )
    .catch(err => {
        if(err.response && err.response.data){
            error = err.response.data
        }
    });
    
    if(data) yield put(CustomersActions.list_customers_response(data))
    else if(error) yield put(CustomersActions.list_customers_error(error))
}
function* watchList(){
    yield takeLatest(CustomersActions.CUSTOMERS_LIST, list);
}


export default function* CustomersSaga(){
    yield all([
        watchList()
    ])
}