import React, {useEffect, useContext} from 'react';
import { Button, SecondaryButton,  MiniContainer, SecondaryText, Container, RouterLink, CloseWarning } from '../../assets/GlobalStyle';
import { Link } from 'react-router-dom';
// import ThemeContext from '../../Components/Contexts/ThemeContext';
import { TextInput } from './styles';
import { useDispatch, connect } from 'react-redux';
import * as OrderActions from '../../data/actions/OrderActions'
import { ThemeContext } from 'styled-components';

function CustomerPhone(props) {
    const {order_store = {}, slug = ''} = props;

    const order = order_store.data;

    const dispatch = useDispatch();

    const theme = useContext(ThemeContext);

    const handleChange = (e) => {
        dispatch(OrderActions.set_order({
            store: slug,
            order: {
                ...order,
                phone: e.target.value
            }
        }))
    }

    useEffect(() => {
        dispatch(OrderActions.load_order(slug))
    }, [dispatch])

    const phoneMask = (v) => {
        var r = v.replace(/\D/g,"");
        r = r.replace(/^0/,"");
        if (r.length > 10) {
            // 11+ digits. Format as 5+4.
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/,"($1) $2-$3");
        }
        else if (r.length === 6) {
            // 6..10 digits. Format as 4+4
            r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/,"($1) $2");
        }
        else if (r.length > 5) {
            // 6..10 digits. Format as 4+4
            r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/,"($1) $2-$3");
        }
        else if (r.length > 2) {
            // 3..5 digits. Add (0XX..)
            r = r.replace(/^(\d\d)(\d{0,5})/,"($1) $2");
        }
        else {
            // 0..2 digits. Just add (0XX
            r = r.replace(/^(\d*)/, "($1");
        }
        return r;
    }

    let type_options = 0;

    if(theme.enable_table)      type_options++;
    if(theme.enable_delivery)   type_options++;
    if(theme.enable_take_away)  type_options++;

    return (
        <React.Fragment>
            {theme.is_opened !== undefined && !theme.is_opened && <CloseWarning>ESTAMOS FECHADOS</CloseWarning>}

            <Container className={'vertical-align center'}>
                <MiniContainer style={{alignItems: 'center'}}>
                    <SecondaryText>{order.customer_name ? order.customer_name : 'Agora'}, precisamos de um telefone para entrar em contato caso algo dê errado com seu pedido...</SecondaryText>
                    <TextInput
                        onChange={handleChange}
                        value={order.phone ? phoneMask(order.phone) : ''}
                        />
                </MiniContainer>
                <RouterLink>
                    <Link to={'/identificacao'}>
                        <SecondaryButton>VOLTAR</SecondaryButton>
                    </Link>
                    {order.phone && order.phone.length >= 14 && 
                        <React.Fragment>
                            {(type_options > 1 || type_options === 0 || (order.table_number !== undefined && order.table_number !== null)) && <Link to="/receber">
                                <Button>CONTINUAR</Button>
                            </Link>}

                            {(type_options === 1 && (order.table_number === undefined || order.table_number === null) && theme.enable_delivery) && <Link to="/endereco">
                                <Button>CONTINUAR</Button>
                            </Link>}

                            {(type_options === 1 && (order.table_number === undefined || order.table_number === null) && !theme.enable_delivery) && <Link to="/pagamento">
                                <Button>CONTINUAR</Button>
                            </Link>}
                        </React.Fragment>
                    }

                    {// eslint-disable-next-line
                    (!order.phone || (order.phone && order.phone.length < 14)) && <a href="">
                        <Button disabled onClick={() => alert('Precisamos de um telefone de contato')}>CONTINUAR</Button>
                    </a>}
                </RouterLink>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
  order_store: state.order
});

export default connect(mapStateToProps)(CustomerPhone)