export const PRODUCTS_LIST                          = 'PRODUCTS_LIST';
export const PRODUCTS_LIST_RESPONSE                 = 'PRODUCTS_LIST_RESPONSE';
export const PRODUCTS_ERROR                         = 'PRODUCTS_ERROR';
export const PRODUCTS_CATEGORIES_LIST               = 'PRODUCTS_CATEGORIES_LIST';
export const PRODUCTS_CATEGORIES_LIST_RESPONSE      = 'PRODUCTS_CATEGORIES_LIST_RESPONSE';
export const PRODUCTS_CATEGORIES_ERROR              = 'PRODUCTS_CATEGORIES_ERROR';

export const list = (store) => {
    return {
        type: PRODUCTS_LIST,
        store
    }
}

export const listResponse = (productsList) => {
    return {
        type: PRODUCTS_LIST_RESPONSE,
        productsList
    }
}

export const productsError = (error) => {
    return {
        type: PRODUCTS_ERROR,
        error
    }
}

export const listCategories = (store) => {
    return {
        type: PRODUCTS_CATEGORIES_LIST,
        store
    }
}

export const listCategoriesResponse = (categoriesList) => {
    return {
        type: PRODUCTS_CATEGORIES_LIST_RESPONSE,
        categoriesList
    }
}

export const productsCategoriesError = (error) => {
    return {
        type: PRODUCTS_CATEGORIES_ERROR,
        error
    }
}


