import api from './api';

export const ProductsService = {
    list: (store) => {
        // let endpoint = `/products?id_category=${catetory.id}`;
        let endpoint = `public/products/${store}`

        return api.get(endpoint);
    },
    listCategories: (store) => {
        let endpoint = `public/categories/${store}`

        return api.get(endpoint);
    }
}
