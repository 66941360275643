import React, { useEffect, useState, useContext, useRef, createRef, useLayoutEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {OrderContainer, ProductsContainer, Category, ProductsList, OrderList, OrderListItem, OrderConfirm, OrderResume, ErrorWrapper} from './styles'
import ProductItem from '../../Components/ProductItem';
import * as ProductsActions from '../../data/actions/ProductsActions';
import * as OrderActions from '../../data/actions/OrderActions';
import ProductConfig from '../../Components/ProductConfig';
import { baseURL } from '../../data/services/api';
import { ThemeContext } from 'styled-components';
import utils from '../../utils';
import { Link } from 'react-router-dom';

import empty_cart_svg from '../../assets/empty_cart.svg'
import Icon from '../../assets/icon';
import Loading from '../../Components/Loading';
import { Button, RouterLink, CloseWarning } from '../../assets/GlobalStyle';
import { toastr } from 'react-redux-toastr';
import CategoriesNav from '../../Components/CategoriesNav';

function Order(props) {
    const {products_store = {}, categories_store = {}, order_store = {items: []}, features = {}, slug = ''} = props;

    const products = products_store.data || [];

    const categories = categories_store.data || [];

    const order = order_store.data;

    const dispatch = useDispatch();
    const [product, setProduct] = useState(null);
    const [detailedOrder, setDetailedOrder] = useState(false)

    const theme = useContext(ThemeContext);

    useEffect(() => {
        dispatch(ProductsActions.list(slug))
        dispatch(ProductsActions.listCategories(slug))
        dispatch(OrderActions.load_order(slug))
    }, [dispatch, slug])


    // remove categories without products
    const filteredCategories = categories.filter(cat => {
        if(!cat.status) return false;
        else{
            return products.filter(prod => prod.id_category === cat.id && prod.status).length > 0;
        }
    })

    const add_item = (item) => {
        let check_required = true;

        product.optionals.forEach(cat => {
            if(cat.status && cat.min > 0){

                let item_opts = item.data.optional.map(item_opt => item_opt.data.id_optional)

                let cat_opts = cat.optional.filter(cat_opt => {
                    if(item_opts.includes(cat_opt.id)) return true;
                    else return false;
                })

                if(cat_opts.length < cat.min) check_required = false;
                
            }
        })

        if(!check_required) {
            toastr.warning("Preencha todas as informações obrigatórias!")

            return;
        }

        let tmpOrder = {...order};

        tmpOrder.items.push(item)

        setProduct(null)

        dispatch(OrderActions.set_order({
            store: slug,
            order: {...tmpOrder}
        }))
    }

    const remove_item = (idx) => {
        let tmpOrder = {...order};

        tmpOrder.items = order.items.filter((item, index) => index !== idx)

        dispatch(OrderActions.set_order({
            store: slug,
            order: {...tmpOrder}
        }))
    }

    const parse_optionals = (opts) => {
        let optionals = {}

        let res = [];

        if(opts){
            opts.forEach(opt => {
                if(optionals[opt.data.id_category]){
                    optionals[opt.data.id_category].values.push(`${opt.data.name ? (opt.data.name) : ''}${opt.data.price > 0 ? ` +R$${utils.number_format(opt.data.price,2,',','')}` : ``}`)
                }
                else{
                    optionals[opt.data.id_category] = {
                        name: opt.data.name_category,
                        values: [`${opt.data.name ? (opt.data.name) : ''}${opt.data.price > 0 ? ` +R$${utils.number_format(opt.data.price,2,',','')}` : ``}`]
                    }
                }
            });

            for(let key in optionals){
                res.push(<p key={`order-list-item-${key}`}>{`${optionals[key].name ? (optionals[key].name) : ''}: ${optionals[key].values.join(', ')}.`}</p>)
            }
        }

        return res;
    }

    const isLoading = products_store.isLoading || categories_store.isLoading;

    const error = products_store.error || categories_store.error || null;

    const categories_ref = useRef([]);
    const anchors_ref = useRef([]);
    const container_ref = useRef()

    useEffect(() => {
        if(categories_ref.current) {
            categories_ref.current = categories_ref.current.slice(0, filteredCategories.length)
        }
        
        if(anchors_ref.current){
            anchors_ref.current = anchors_ref.current.slice(0, filteredCategories.length)
        }
    }, [filteredCategories])


    const debounce = useRef();

    const offset = 72

    const handleActiveCategory = () => {
        if(container_ref.current) {
            let scrollPos = container_ref.current.scrollTop;

            let index_active = 0;

            anchors_ref.current.forEach((anch, idx) => {
                let anchPos = anch.offsetTop;

                if(anchPos <= (scrollPos + offset)) index_active = idx
            })

            categories_ref.current.forEach((cat, idx) => {
                if(idx === index_active){
                    cat.classList.add('active')
                    cat.scrollIntoView();
                }
                else{
                    cat.classList.remove('active')
                }
            })
        
        }
    }

    const handleScroll = () => {
        if(debounce.current){
            clearInterval(debounce.current)
        }
        
        debounce.current = setTimeout(() => {
            handleActiveCategory()
        }, 50)
    }

    
    useEffect(() => {
        if(container_ref.current !== undefined && container_ref.current !== null){
            container_ref.current.addEventListener('scroll', handleScroll)
        }
        
        setTimeout(() => {
            handleActiveCategory()
        }, 500);
    }, [container_ref])

    return (
        <React.Fragment>
            <CategoriesNav refs={categories_ref} anchors={anchors_ref} categories={filteredCategories} />
            
            <ProductsContainer isLoading={error !== null || isLoading} ref={container_ref}>
                {theme.is_opened !== undefined && !theme.is_opened && <CloseWarning>ESTAMOS FECHADOS</CloseWarning>}


                {error && <ErrorWrapper>
                    <p>Algo deu errado... :/</p>
                    <p>Não conseguimos encontrar a lista de produtos deste estabelecimento...</p>
                    <RouterLink>
                        {/* eslint-disable-next-line*/}
                        <a name="error_products">
                            <Button onClick={() => {
                                dispatch(ProductsActions.list(slug))
                                dispatch(ProductsActions.listCategories(slug))
                                dispatch(OrderActions.load_order(slug))
                            }}>Tentar novamente</Button>
                        </a>
                    </RouterLink>
                </ErrorWrapper>}
                {!error && isLoading && <Loading color={theme.accentColor} />}

                {!error && !isLoading && filteredCategories.map((cat, catIdx) => <React.Fragment key={`category-key-${catIdx}`}>
                    <Category ref={el => anchors_ref.current[catIdx] = el}>{cat.name}</Category>
                    <ProductsList>
                        {products.filter(prod => prod.id_category === cat.id && prod.status).map((prod, idx) => <ProductItem key={`prod-${idx}`} product={prod} onSelect={prod => setProduct(prod)} />)}
                    </ProductsList>
                </React.Fragment>)}

                <div style={{height: '100%'}} />
            </ProductsContainer>
            
            {!error && !isLoading && <OrderContainer className={`${!order.items || order.items.length === 0 ? 'no-order' : ''} ${detailedOrder ? 'extended' : ''}`}>
                <OrderResume onClick={() => setDetailedOrder(!detailedOrder)}>
                    {!detailedOrder && products.length > 0 && order.items && order.items.length > 0 && <React.Fragment>
                        {utils.getQntItems(order.items)} {utils.getQntItems(order.items) > 1 ? 'items' : 'item'} - R$ {utils.getTotal(order.items)} - FECHAR PEDIDO
                    </React.Fragment>}

                    {detailedOrder ? '< VOLTAR' : ''}
                </OrderResume>

                {products.length === 0 || !order.items || ( order.items.length === 0) ? <React.Fragment>
                    <img src={empty_cart_svg} width={200} alt={'Carrinho vazio...'} />
                    <p>Carrinho vazio...</p>
                </React.Fragment> : ''}

                {products.length > 0 && order.items && order.items.length > 0 && 
                    <React.Fragment>
                        <OrderList className={detailedOrder ? 'extended' : ''}>
                            {
                                order.items.map((ele,idx) => {
                                    const [product] = products.filter(prod => prod.id === ele.data.id_product);

                                    return !product ? null : <OrderListItem key={`order-list-item-${idx}`}>
                                        <img className={'thumb'} alt='LanchOS' src={product.thumb ? `${baseURL}/media/products/${product.thumb}` : `${baseURL}/media/settings/${theme.logo}`} />
                                        <div>

                                            <h2>{`${ele.data.qnt} - ${product.name} - R$${utils.number_format(ele.data.price,2,',','')}`}</h2>
                                            {parse_optionals(ele.data.optional)}
                                        </div>    
                                        <div className={'actions'}>
                                            <button onClick={() => remove_item(idx)}>
                                                <Icon icon={'delete'} size={24} />
                                            </button>
                                        </div>
                                    </OrderListItem>
                                })
                            }
                        </OrderList>
                        {features.data.observations_feature && <Link to="/observacoes">
                            <OrderConfirm className={detailedOrder ? 'extended' : ''}>CONFIRMAR PEDIDO POR R${utils.getTotal(order.items,2,',','')}</OrderConfirm>
                        </Link>}
                        {!features.data.observations_feature && <Link to="/identificacao">
                            <OrderConfirm className={detailedOrder ? 'extended' : ''}>CONFIRMAR PEDIDO POR R${utils.getTotal(order.items,2,',','')}</OrderConfirm>
                        </Link>}
                    </React.Fragment>
                }
            </OrderContainer>}

            {product && <ProductConfig 
                product={product} 
                onCancel={() => setProduct(null)} 
                onAdd={(item) => {
                    add_item(item)
                    }} />}
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
  order_store: state.order,
  products_store: state.stock.products,
  categories_store: state.stock.categories,
  features: state.settings.features
});

export default connect(mapStateToProps)(Order);