import React, {useEffect} from 'react'
import {
    HashRouter as HRouter,
    useParams,
    useHistory
} from "react-router-dom";
import { useDispatch, connect } from 'react-redux';
import * as SettingsActions from '../../data/actions/SettingsActions';
import ThemeWrapper from '../ThemeWrapper';
import Loading from '../../views/Loading';
import { RouterLink, Button, MiniContainer, PrimaryText } from '../../assets/GlobalStyle';
import Container from '../Container';


function CustomHashRouter(props) {
    const {slug = 'lanchos'} = useParams();

    const {styles = {}} = props;

    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
      let action = {
        slug,
        onSuccess: (teste) => {
          // history.push('#/')
        }
      }
      dispatch(SettingsActions.getStyles(action))
      dispatch(SettingsActions.paymentsList(slug))
      dispatch(SettingsActions.actionSettingsFeaturesRequest(slug))
    }, [slug, dispatch, history])

    return (
        <HRouter>
          {styles.isLoading && <Loading />}
          {!styles.isLoading && !styles.error && <ThemeWrapper slug={slug} />}
          {styles.error && <React.Fragment>
            <Container className={'vertical-align center'}>
              <MiniContainer style={{width: '100%'}}>
                <PrimaryText>Houve um problema ao buscar as informações deste restaurante...</PrimaryText>
                <RouterLink>
                  {/* eslint-disable-next-line*/}
                  <a name="try_again">
                    <Button onClick={() => dispatch(SettingsActions.getStyles({slug}))}>Tentar novamente</Button>
                  </a>
                </RouterLink>
              </MiniContainer>
            </Container>
          </React.Fragment>}
        </HRouter>
    )
}

const mapStateToProps = state => {
  return ({
    styles: state.settings.styles
  })
} ;

export default connect(mapStateToProps)(CustomHashRouter);