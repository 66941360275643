import styled from 'styled-components';
import {themeColor} from '../../assets/GlobalStyle'

export const TableStatus = styled.table`
    margin: 0;
    padding: 0;
    border: 0;
    background-color: ${props => themeColor(props, 'accentColor')};
    border-radius: 5px;
    width: 80%;
    max-width: 400px;
    min-width: 320px;
    border-collapse: collapse;

    tr {
        &:first-child{
            td {
                border-top: none;
            }
        }

        &:last-child{
            td {
                border-bottom: none;
            }
        }

        td {
            padding: 5px 10px;
            border:2px solid ${props => themeColor(props, 'backgroundColor')};
            color: ${props => themeColor(props, 'accentTextColor')};

            &:last-child{
                border-right: none;
                text-align: left;
            }

            &:first-child {
                border-left: none;
            }
        }
    }
`;