import * as CustomersActions from '../actions/CustomersActions';

const CustomersReducer = (
    customers = {
        isSaving: false,
        isLoading: false,
        error: false,
        data: []
    }, 
    action
    ) => {
    
    switch(action.type){
        case CustomersActions.CUSTOMERS_LIST:
            return {
                ...customers,
                isLoading: true
            }
        
        case CustomersActions.CUSTOMERS_LIST_RESPONSE:
            return {
                ...customers,
                data: [
                    ...action.customers
                ],
                isLoading: false,
                error: false
            }

        case CustomersActions.CUSTOMERS_LIST_ERROR:
            return {
                ...customers,
                isLoading: false,
                error: action.error
            }

        default: return customers;
    }
}

export default CustomersReducer;
