import React from 'react';
import Container from '../../Components/Container';

export default function OrderType(props) {
    return (
        <Container style={{flexDirection: 'column'}}>
            <div className={'text-center'} style={{display: 'flex', flexDirection:'column', width: '100%', flex:1, justifyContent:'center'}}>
                <p>Página não encontrada</p>
            </div>
        </Container>
    )
}
