import styled from 'styled-components';
import {themeColor} from '../../assets/GlobalStyle'

export const TypesContainer = styled.div`
  
`;

// const themeColor = (props, color) => props.theme ? props.theme[color] : colors()[color];

export const CircleIcon = styled.div`
    width: 100px;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button{
        width: 70px;
        height: 70px;
        border-radius: 35px;
        background-color: ${props => props.active ? themeColor(props, 'accentColor') : themeColor(props, 'backgroundColor')};
        display: block;
        border:2px solid ${props => props.active ? themeColor(props, 'accentTextColor') : themeColor(props, 'secondTextColor')};
        color: ${props => props.active ? themeColor(props, 'accentTextColor') : themeColor(props, 'secondTextColor')};
    }

    p {
        font-size: 14px;
        color: ${props => themeColor(props, 'secondTextColor')}
    }
`;

export const TyperWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;