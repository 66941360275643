import api from './api';
import querystring from 'query-string'

export const CustomersService = {
    list: (filter) => {
        // let endpoint = `/products?id_category=${catetory.id}`;
        let default_filter = {
            page_limit: 'all',
            ...filter
        }
        let endpoint = `public/shops`

        return api.get(endpoint);
    }
}
