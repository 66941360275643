import React from 'react';
import Container from '../../Components/Container';
import Icon from '../../assets/icon';
import { PrimaryText } from '../../assets/GlobalStyle';

export default function Loading(props) {
    return (
        <Container style={{flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <Icon icon={'lanchos'} size={300} />
            <PrimaryText>Aguarde....</PrimaryText>
        </Container>
    )
}
