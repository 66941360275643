import api from './api';
import auth from './auth';

export const SettingsService = {
    getPayments: (store) => {
        return api.get(`/public/paymentmethods/${store}`);
    },

    getDeliveryCharges: (store) => {
        return api.get(`/public/delivery/${store}`);
    },

    getStyles: (store) => {
        return api.get(`/public/styles/${store}`);
    },

    getSettings: () => {
        return api.get('/settings', {
            headers: {Authorization: `Bearer ${auth.getToken()}`}
        });
    },

    serviceFeatures: (slug) => {
        return api.get(`/public/features/${slug}`);
    },
}
