import React, { useContext, useEffect, useState } from 'react';
import { MiniContainer, SecondaryText, Container, RouterLink, Button } from '../../assets/GlobalStyle';
import { useParams, Link } from 'react-router-dom';
import { connect, useDispatch, useStore } from 'react-redux';
import * as OrderActions from '../../data/actions/OrderActions'

import loading_svg from '../../assets/loading.svg'
import success_svg from '../../assets/success.svg'
import error_svg from '../../assets/error.svg'
import { OrderService } from '../../data/services/OrderService';
import { TableStatus } from './styles';
import { actionSettingsFeaturesRequest } from '../../data/actions/SettingsActions';
import { ThemeContext } from 'styled-components';

const checkStatus = {
    interval: null
};

function Status(props) {
    const {features = {}, slug = ''} = props;

    const theme = useContext(ThemeContext);

    const [accepted, setAccepted] = useState(false)
    const [processed, setProcessed] = useState(false)
    const [orderType, setOrderType] = useState('M')
    const [waitTime, setWaitTime] = useState(0)
    const [dateCreated, setDateCreated] = useState()
    const [dateDelivered, setDateDelivered] = useState()
    const [dateAccepted, setDateAccepted] = useState()
    const [dateFinished, setDateFinished] = useState()

    const { order_id } = useParams();

    const dispatch = useDispatch();

    const time_retry = 5000;

    const check_status = () => {
        clearInterval(checkStatus.interval);

        OrderService.checkStatus(slug, order_id)
        .then(res => {
            const {data} = res;

            setProcessed(data.status)
            setAccepted(data.accepted)
            setOrderType(data.type)
            setWaitTime(data.wait_time)

            // console.log('data', data);
            if(data.date_create) setDateCreated(new Date(data.date_create).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}))

            if(data.date_accepted) setDateAccepted(new Date(data.date_accepted).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}))

            if(data.date_delivered) setDateDelivered(new Date(data.date_delivered).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}))

            if(data.date_finished) setDateFinished(new Date(data.date_finished).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}))

            if(
                !data.status ||
                (data.type === "T" && !data.date_delivered) ||
                (data.type !== "T" && !data.date_finished) 
                ) {
                
                checkStatus.interval = setTimeout(() => {
                    check_status();
                }, time_retry);
            }
        })
        .catch(err => {
            checkStatus.interval = setTimeout(() => {
                check_status();
            }, time_retry);
        })
    }

    useEffect(() => {
        let newType;

        if(theme.enable_table) newType = 'M';
        else if(features.enable_delivery) newType = 'T';
        else newType = 'L';

        dispatch(actionSettingsFeaturesRequest(slug))
        dispatch(OrderActions.set_order({
            store: slug,
            order: {
                items: [],
                type: newType
            }
        }))
    }, [dispatch])

    useEffect(() => {
        if(order_id){
            // socket.init(order_id, () => {
            //     socket.emit('status_update', order_id)

            //     socket.socket.on('status_update', (res) => {
            //         setProcessed(res.status)
            //         setAccepted(res.accepted)
            //         setOrderType(res.type)
            //     })
            // })

            check_status();
        }
    }, [order_id])
    
    return (
        <Container className={'vertical-align center'}>
            <MiniContainer style={{alignItems: 'center'}}>
                {!processed && <React.Fragment>
                    <SecondaryText>Aguarde um momento, enquanto confirmamos seu pedido....</SecondaryText>
                    <img src={loading_svg} width={200} alt={'Loading'} />
                    <SecondaryText>Este é o número do seu pedido: #{order_id}</SecondaryText>
                    <SecondaryText>Caso haja demora na confirmação do seu pedido, tente atualizar esta página...</SecondaryText>
                </React.Fragment>}

                {processed && accepted && <React.Fragment>
                    <img src={success_svg} width={100} alt={'Pedido confirmado'} />
                    <SecondaryText>Seu pedido foi recebido!!! {orderType === 'T' ? 'Em breve estaremos entregando no local escolhido!' : 'Em breve seu pedido estará disponível!'}</SecondaryText>

                    <TableStatus>
                        <tbody>
                            <tr>
                                <td width={80}>{dateCreated}</td>
                                <td>Você confirmou o pedido</td>
                            </tr>
                            <tr>
                                <td>{dateAccepted}</td>
                                <td>O restaurante recebeu seu pedido</td>
                            </tr>

                            {orderType === 'T' && features.data.delivered_feature &&
                            <tr>
                                <td>{dateDelivered ? dateDelivered : '--:--:--'}</td>
                                <td>{dateDelivered ? 'Saiu para entrega' : 'Aguardando saída para enrtega'}</td>
                            </tr>}

                            {orderType === 'L' && features.data.finished_feature &&
                            <tr>
                                <td>{dateFinished ? dateFinished : '--:--:--'}</td>
                                <td>{dateFinished ? 'Pedido pronto para ser retirado' : 'Preparando pedido...'}</td>
                            </tr>}

                            {orderType === 'M' && features.data.finished_feature &&
                            <tr>
                                <td>{dateFinished ? dateFinished : '--:--:--'}</td>
                                <td>{dateFinished ? 'Pedido pronto' : 'Preparando pedido...'}</td>
                            </tr>}
                        </tbody>
                    </TableStatus>
                    <SecondaryText style={{marginTop: 30, fontSize: 20}}>Número do pedido: #{order_id}</SecondaryText>
                    {waitTime > 0 && <SecondaryText style={{fontSize: 20}}>Tempo aproximado de espera: {waitTime} minutos</SecondaryText>}
                </React.Fragment>}

                {processed && !accepted && <React.Fragment>
                    <SecondaryText>Infelizmente, não foi possível confirmar seu pedido... :/</SecondaryText>
                    <img src={error_svg} width={200} alt={'Pedido cancelado'} />   
                </React.Fragment>}
            </MiniContainer>

            <RouterLink>
                {processed && <Link to="/pedido">
                    <Button>NOVO PEDIDO</Button>
                </Link>}                
            </RouterLink>
        </Container>
    )
}

const mapStateToProps = state => {
    return ({
        order_store: state.order,
        features: state.settings.features
    })
}

export default connect(mapStateToProps)(Status);