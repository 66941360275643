import styled from 'styled-components';
import {themeColor} from '../../assets/GlobalStyle'

export const PaymentMethod = styled.div`
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;

    button{
        width: auto;
        padding: 5px 15px;
        display: block;
        border: none;
        background-color: ${props => props.active ? themeColor(props, 'accentColor') : themeColor(props, 'accentTextColor')};
        color: ${props => props.active ? themeColor(props, 'accentTextColor') : themeColor(props, 'accentColor')};
        border-radius: 35px;
    }
`;

export const SelectWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    select {
        width: 250px;
        height: 35px;
        border-radius: 50px;
        padding: 0 10px;
        border: 2px solid ${props => themeColor(props, 'secondTextColor')};
        background-color: ${props => themeColor(props, 'backgroundColor')};
        color: ${props => themeColor(props, 'secondTextColor')};
    }
`;

export const TextInput = styled.input`
    width: 200px;
    height: 45px;
    background-color: ${props => themeColor(props, 'backgroundColor')};
    color: ${props => themeColor(props, 'secondTextColor')};
    border-radius: 50px;
    text-align: center;
    border: 2px solid ${props => themeColor(props, 'secondTextColor')};
    align-self: center;

    
`;

export const ErrorWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p{ 
        color: ${props => themeColor(props, 'secondaryTextColor')}
    }
`;