import styled from 'styled-components';
import {themeColor} from '../../assets/GlobalStyle'

export const PaymentMethod = styled.div`
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;

    button{
        width: auto;
        padding: 5px 15px;
        display: block;
        border: 2px solid ${props => props.active ? themeColor(props, 'accentColor') : themeColor(props, 'secondTextColor')};
        background-color: ${props => props.active ? themeColor(props, 'accentColor') : themeColor(props, 'backgroundColor')};
        color: ${props => props.active ? themeColor(props, 'accentTextColor') : themeColor(props, 'secondTextColor')};
        border-radius: 35px;
    }
`;

export const MethodsWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

export const TextInput = styled.input`
    width: 200px;
    height: 45px;
    background-color: ${props => themeColor(props, 'primaryTextColor')};
    color: ${props => themeColor(props, 'primaryColor')};
    border-radius: 50px;
    text-align: center;
    border: none;
    align-self: center;

    
`;