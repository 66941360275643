import * as ProductsActions from '../actions/ProductsActions';

const ProductsReducer = (
    stock = {
        products: {
            isLoading: false,
            error: null,
            data: []
        },
        categories: {
            isLoading: false,
            error: null,
            data: []
        }
    }
    , action) => {
    switch(action.type){
        case ProductsActions.PRODUCTS_LIST:
            return {
                ...stock,
                products: {
                    ...stock.products,
                    isLoading: true,
                    error: null
                }
            }
        case ProductsActions.PRODUCTS_LIST_RESPONSE:
            return {
                ...stock,
                products: {
                    ...stock.products,
                    isLoading: false,
                    error: null,
                    data: action.productsList
                }
            }
        
        case ProductsActions.PRODUCTS_ERROR:
            return {
                ...stock,
                products: {
                    ...stock.products,
                    isLoading: false,
                    error: action.error
                }
            }

        case ProductsActions.PRODUCTS_CATEGORIES_LIST:
            return {
                ...stock,
                categories: {
                    ...stock.categories,
                    isLoading: true,
                    error: null
                }
            }
        case ProductsActions.PRODUCTS_CATEGORIES_LIST_RESPONSE:
            return {
                ...stock,
                categories: {
                    ...stock.categories,
                    isLoading: false,
                    error: null,
                    data: action.categoriesList
                }
            }

        case ProductsActions.PRODUCTS_CATEGORIES_ERROR:
            return {
                ...stock,
                categories: {
                    ...stock.categories,
                    isLoading: false,
                    error: action.error
                }
            }
        default: return stock;
    }
}

export default ProductsReducer;
