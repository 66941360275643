const colors = {
    primaryColor:           '#2a2e3b', //#057302
    accentColor:            '#FFFFFF',

    primaryTextColor:       '#FFFFFF',
    secondTextColor:        '#CCCCCC',
    accentTextColor:        '#003557',

    detailAreaColor:        '#254961',
    detailTextColor:        '#FFFFFF',
    backgroundColor:        '#2a2e3b', //003557
    logo:                   null
}

export const setColor = (key, value) => {
    colors[key] = value;
}

const getColors = () => colors;

export default getColors