import styled, {createGlobalStyle} from 'styled-components';
import colors from './colors';
import media  from "styled-media-query";

export const themeColor = (props, color) => props.theme ? props.theme[color] : colors()[color];

export const breakPoints = {
    xs: '576px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px'
  };

export const GlobalStyle = createGlobalStyle`
    :root {
        --app-height: 100vh;
    }

    body{
        background-color: ${props => themeColor(props, 'backgroundColor')};
        scroll-behavior: smooth;

        @media not all and (hover:hover) {
            height: 100%;
            /* height: var(--app-height); */
        }
    }

    ::-webkit-scrollbar {
        width: 8px; /* 1px wider than Lion. */
        /* This is more usable for users trying to click it. */
        background-color: rgba(0,0,0,0);
        border-radius: 100px;
        position: absolute;
    }
    /* hover effect for both scrollbar area, and scrollbar 'thumb' */
    ::-webkit-scrollbar:hover {
        background-color: rgba(0, 0, 0, 0.09);
    }

    /* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
    ::-webkit-scrollbar-thumb:vertical {
    /* This is the EXACT color of Mac OS scrollbars. 
        Yes, I pulled out digital color meter */
    background-color: rgba(0,0,0,0.5);
    border-radius: 100px;
    }
    ::-webkit-scrollbar-thumb:vertical:active {
        background-color: rgba(0,0,0,0.61); /* Some darker color when you click it */
        border-radius: 100px;
    }

    background: linear-gradient(45deg, white, silver);
    min-height: 100%;
`;

export const BottomActionsWrapper = styled.div`
    position: fixed;
    bottom:0;
    width: 100%;
    /* transform: translateY(-100%); */

    ${media.greaterThan(breakPoints.xs)`
        position: relative;
    `}
`;

export const Button = styled.button`
    color: ${props => themeColor(props, 'accentTextColor')};
    background-color: ${props => themeColor(props, 'accentColor')};
    border: 2px solid ${props => themeColor(props, 'accentColor')};
    size: 22px;
    padding: 0 20px;
    height: 72px;
    margin: 0;
    /* border: none; */
    display: flex;
    flex: 0 0 100%;
    align-items: center;

    &:disabled{
        opacity: 0.5;
    }

    ${media.greaterThan(breakPoints.xs)`
        border-radius: 0 50px 50px 0;

        height: auto;
        padding: 10px 20px;
    `}
`;

export const SecondaryButton = styled.button`
    background-color: ${({theme}) => theme && theme.accentTextColor ? theme.accentTextColor : colors().accentTextColor};
    color: ${({theme}) => theme && theme.accentColor ? theme.accentColor : colors().accentColor};
    border: 2px solid ${props => themeColor(props, 'accentColor')};
    size: 22px;
    padding: 0 20px;
    height: 72px;
    margin: 0;
    /* border: none; */
    display: flex;
    flex: 0 0 100%;
    align-items: center;

    &:disabled{
        opacity: 0.5;
    }

    ${media.greaterThan(breakPoints.xs)`
        height: auto;
        padding: 10px 20px;
    `}
`;

export const Container = styled.div`
    width: 100%;
    height: 100%;
    /* height: var(--app-height); */
    display:flex;
    flex-direction: column;

    &.vertical-align{
        height: 100%;
        /* height: var(--app-height); */
        justify-content: center;
    }

    &.center {
        align-items: center;
    }
`;

export const MiniContainer = styled.div`
    width: 70%; 
    display: flex;
    flex-direction :column;
    /* width: '100%';  */
    justify-content: center; 
    min-height: 70%;
    align-content: center;
    text-align: center;
    box-sizing: border-box;
    padding: 10px;
    padding-bottom: 82px;

    ${media.lessThan(breakPoints.xs)`
        flex: 1;
        width: 100%;
    `}

    ${media.greaterThan(breakPoints.xs)`
        padding-bottom: 10px;
    `}

    img.logo{
        display: block;
        height: 100px;
        flex: 0 0 auto;
        object-fit: scale-down;
    }
`;

export const RouterLink = styled.div`
    display: flex;
    flex: 0;
    width: 100%;
    justify-content:center;
    position: fixed;
    bottom:0;
    width: 100%;
    /* transform: translateY(-100%); */

    ${media.greaterThan(breakPoints.xs)`
        position: relative;
    `}

    ${media.lessThan(breakPoints.xs)`
        width: 100%;

        button {
            width: 100%;
            justify-content: center;
        }
    `}

    &:nth-last-child(){
        button {
            ${media.greaterThan(breakPoints.xs)`
                border-radius: 0 50px 50px 0;
            `}
        }
    }

    &:first-child{
        button {
            ${media.greaterThan(breakPoints.xs)`
                border-radius: 50px 0 0 50px;
            `}
        }
    }

    &:only-child{
        button {
            ${media.greaterThan(breakPoints.xs)`
                border-radius: 50px;
            `}
        }
    }

    a{ 
        text-decoration:none;

        ${media.lessThan(breakPoints.xs)`
            width: 100%;

            button {
                width: 100%;
                justify-content: center;
            }
        `}

        &:nth-last-child(){
            button {
                ${media.greaterThan(breakPoints.xs)`
                    border-radius: 0 50px 50px 0;
                `}
            }
        }

        &:first-child{
            button {
                ${media.greaterThan(breakPoints.xs)`
                    border-radius: 50px 0 0 50px;
                `}
            }
        }

        &:only-child{
            button {
                ${media.greaterThan(breakPoints.xs)`
                    border-radius: 50px;
                `}
            }
        }
    }
`;

export const PrimaryText = styled.p`
  color: ${props => props.theme ? props.theme.primaryTextColor : colors().primaryTextColor};
`;

export const SecondaryText = styled.p`
  color: ${props => props.theme ? props.theme.secondTextColor : colors().secondTextColor};
`;

export const CloseWarning = styled.div`
    width: 100%;
    height: 40px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => themeColor(props, 'accentColor')};
    color: ${props => themeColor(props, 'accentTextColor')};
`