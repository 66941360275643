import React, { useEffect } from 'react'
import {
    Route
} from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import Introduction from '../../views/Introduction';
import OrderType from '../../views/OrderType';
import CustomerName from '../../views/CustomerName';
import CustomerPhone from '../../views/CustomerPhone';
import Payment from '../../views/Payment';
import Splash from '../../views/Splash';
import Order from '../../views/Order';
import Address from '../../views/Address';
import Status from '../../views/Status';
import colors from '../../assets/colors';
import { connect, useDispatch } from 'react-redux';

import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../../assets/GlobalStyle'
import Observations from '../../views/Observations';
import { useQuery } from '../../hooks/useQuery';
import { order_set_table, set_order } from '../../data/actions/OrderActions';

function ThemeWrapper(props) {

  const { theme = colors(), slug = 'lanchos', order } = props;

  const routes = [
      { path: '/', name: 'LanhOS', Component: Splash },
      { path: '/inicio', name: 'Início', Component: Introduction },
      { path: '/observacoes', name: 'Alguma observação?', Component: Observations },
      { path: '/receber', name: 'Como receber?', Component: OrderType },
      { path: '/identificacao', name: 'Qual seu nome?', Component: CustomerName },
      { path: '/telefone', name: 'Qual seu contato?', Component: CustomerPhone },
      { path: '/pedido', name: 'Qual seu pedido?', Component: Order },
      { path: '/pagamento', name: 'Como deseja efetuar o pagamento?', Component: Payment },
      { path: '/endereco', name: 'Onde deseja receber o pedido?', Component: Address },
      { path: '/status/:order_id', name: 'Acompanhe seu pedido', Component: Status }
  ]

  const query = useQuery();
  
  const mesa = query.get('mesa');

  const dispatch = useDispatch();

  useEffect(() => {
    if(mesa && order){
      dispatch(set_order({
        store: slug,
        order: {
          ...order,
          type: "M",
          table_number: parseInt(mesa)
        }
      }))
    }
  }, [mesa, dispatch])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {slug !== '404' && routes.map(({ path, Component }) => (
        <Route key={path} exact path={path}>
          {({ match }) => (
            <CSSTransition
              in={match != null}
              timeout={300}
              classNames="pages"
              unmountOnExit
              appear
            >
              <div className="pages">
                <Component slug={slug} {...props} />
              </div>
            </CSSTransition>
          )}
        </Route>
      ))}
    </ThemeProvider>
  )
}

const mapStateToProps = state => ({
  theme: state.settings.styles.data,
  order: state.order.data,
});

export default connect(mapStateToProps)(ThemeWrapper);