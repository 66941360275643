

export const SETTINGS_PRINTERS_LIST                 = 'SETTINGS_PRINTERS_LIST';
export const SETTINGS_PRINTERS_LIST_RESPONSE        = 'SETTINGS_PRINTERS_LIST_RESPONSE';
export const SETTINGS_DEFAULT_PRINTER               = 'SETTINGS_DEFAULT_PRINTER';
export const SETTINGS_LIST                          = 'SETTINGS_LIST';
export const SETTINGS_LIST_RESPONSE                 = 'SETTINGS_LIST_RESPONSE';
export const SETTINGS_STORE                         = 'SETTINGS_STORE';


// Payments

export const SETTINGS_PAYMENTS_LIST                 = 'SETTINGS_PAYMENTS_LIST';
export const SETTINGS_PAYMENTS_LIST_RESPONSE        = 'SETTINGS_PAYMENTS_LIST_RESPONSE';
export const SETTINGS_PAYMENTS_ERROR                = 'SETTINGS_PAYMENTS_ERROR';

export const paymentsList = (store) => {
    return {
        type: SETTINGS_PAYMENTS_LIST,
        store
    }
}

export const paymentsListResponse = (paymentsList) => {
    return {
        type: SETTINGS_PAYMENTS_LIST_RESPONSE,
        paymentsList
    }
}

export const paymentsError = (error) => {
    return {
        type: SETTINGS_PAYMENTS_ERROR,
        error
    }
}


export const getSettings = () => {
    return {
        type: SETTINGS_LIST
    }
}

export const settingsResponse = (settings) => {
    return {
        type: SETTINGS_LIST_RESPONSE,
        settings
    }
}

// Delivery
export const SETTINGS_DELIVERY                      = 'SETTINGS_DELIVERY';
export const SETTINGS_DELIVERY_RESPONSE             = 'SETTINGS_DELIVERY_RESPONSE';
export const SETTINGS_DELIVERY_ERROR                = 'SETTINGS_DELIVERY_ERROR';

export const deliveryCharges = (store) => {
    return {
        type: SETTINGS_DELIVERY,
        store
    }
}

export const deliveryChargesResponse = (delivery) => {
    return {
        type: SETTINGS_DELIVERY_RESPONSE,
        delivery
    }
}

export const deliveryChargesError = error => {
    return {
        type: SETTINGS_DELIVERY_ERROR,
        error
    }
}

// Styles
export const SETTINGS_STYLES_LIST                   = 'SETTINGS_STYLES_LIST';
export const SETTINGS_STYLES_LIST_RESPONSE          = 'SETTINGS_STYLES_LIST_RESPONSE';
export const SETTINGS_STYLES_ERROR                  = 'SETTINGS_STYLES_ERROR';

export const getStyles = (action) => {
    return {
        type: SETTINGS_STYLES_LIST,
        ...action
    }
}

export const stylesResponse = (styles) => {
    return {
        type: SETTINGS_STYLES_LIST_RESPONSE,
        styles
    }
}

export const stylesError = error => {
    return {
        type: SETTINGS_STYLES_ERROR,
        error
    }
}


// Features

export const SETTINGS_FEATURES_REQUEST        = 'SETTINGS_FEATURES_REQUEST';
export const SETTINGS_FEATURES_SUCCESS        = 'SETTINGS_FEATURES_SUCCESS';
export const SETTINGS_FEATURES_FAILURE        = 'SETTINGS_FEATURES_FAILURE';

export const actionSettingsFeaturesRequest = (slug) => {
    return {
        type: SETTINGS_FEATURES_REQUEST,
        slug
    }
}

export const actionSettingsFeaturesSuccess = (features) => {
    return {
        type: SETTINGS_FEATURES_SUCCESS,
        features
    }
}

export const actionSettingsFeaturesFailure = error => {
    return {
        type: SETTINGS_FEATURES_FAILURE,
        error
    }
}