import React from 'react'
import {LoadingComponent, Wrapper} from './styles'

export default function Loading(props) {

    return (
        <Wrapper>
            <LoadingComponent {...props}>
                <div></div><div></div>
            </LoadingComponent>
        </Wrapper>
    )
}
