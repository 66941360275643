import * as ProductsActions from '../actions/ProductsActions';
import {ProductsService} from '../services/ProductsService';

import { all, put, takeLatest } from 'redux-saga/effects';

//INDEX
function* listAll(action){
    let data = null;
    let error = null;
    yield ProductsService.list(action.store).then(
        result => {
            data = result.data.data
        }
    )
    .catch(err => {
        if(err.response && err.response.data){
            error = err.response.data;
        }
    });
    
    if(data) {yield put(ProductsActions.listResponse(data));}
    
    if(error) yield put(ProductsActions.productsError(error));
}
function* watchListAll(){
    yield takeLatest(ProductsActions.PRODUCTS_LIST, listAll);
}

// CATEGORIES
function* listCategories(action){
    let data = null;
    let error = null;
    yield ProductsService.listCategories(action.store).then(
        result => {
            data = result.data.data
        }
    )
    .catch(err => {
        if(err.response && err.response.data){
            error = err.response.data;
        }
    });
    
    if(data) yield put(ProductsActions.listCategoriesResponse(data))
    if(error) yield put(ProductsActions.productsCategoriesError(error))
}
function* watchListCategories(){
    yield takeLatest(ProductsActions.PRODUCTS_CATEGORIES_LIST, listCategories);
}


export default function* ProductsSaga(){
    yield all([
        watchListAll(),
        watchListCategories()
    ])
}