import React, { useEffect, useContext } from 'react';
import { Button, SecondaryButton,  MiniContainer, SecondaryText, Container, RouterLink, CloseWarning } from '../../assets/GlobalStyle';
import { Link } from 'react-router-dom';
import {CircleIcon, TyperWrapper} from './styles'
import { connect, useDispatch } from 'react-redux';
import * as OrderActions from '../../data/actions/OrderActions'
import Icon from '../../assets/icon'
import { ThemeContext } from 'styled-components';

function OrderType(props) {
    const {order_store = {}, slug = ''} = props;

    const order = order_store.data;

    const dispatch = useDispatch();

    const theme = useContext(ThemeContext);

    useEffect(() => {
        dispatch(OrderActions.load_order(slug))
    }, [dispatch])

    const handle_change = type => {
        dispatch(OrderActions.set_order({
            store: slug,
            order: {
                ...order,
                type
            }
        }))
    }

    return (
        <React.Fragment>
            {theme.is_opened !== undefined && !theme.is_opened && <CloseWarning>ESTAMOS FECHADOS</CloseWarning>}

            <Container className={'vertical-align center'}>
                <MiniContainer>
                    <SecondaryText>...falando nisso, como quer receber seu pedido?</SecondaryText>
                    <TyperWrapper>
                        {(theme.enable_table || (order.table_number !== undefined && order.table_number !== null)) && <CircleIcon active={order.type && order.type === 'M'}>
                            <button onClick={() => handle_change('M')}><Icon icon={'table'} size={36} /></button>
                            <p>Comer no local</p>
                        </CircleIcon>}

                        {theme.enable_delivery && <CircleIcon active={order.type === 'T'}>
                            <button onClick={() => handle_change('T')}><Icon icon={'delivery'} size={36} /></button>
                            <p>Delivery</p>
                        </CircleIcon>}

                        {theme.enable_take_away && <CircleIcon active={order.type && order.type === 'L'}>
                            <button onClick={() => handle_change('L')}><Icon icon={'take-away'} size={36} /></button>
                            <p>Buscar</p>
                        </CircleIcon>}
                    </TyperWrapper>
                </MiniContainer>

                <RouterLink>
                    <Link to={'/telefone'}>
                        <SecondaryButton>VOLTAR</SecondaryButton>
                    </Link>
                    {order.type && order.type !== 'T' && <Link to="/pagamento">
                        <Button>CONTINUAR</Button>
                    </Link>}
                    {(order.type && order.type === 'T') && <Link to="/endereco">
                        <Button>CONTINUAR</Button>
                    </Link>}
                    {// eslint-disable-next-line
                        !order.type && <a>
                        <Button disabled>CONTINUAR</Button>
                    </a>}
                </RouterLink>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
  order_store: state.order
});

export default connect(mapStateToProps)(OrderType);