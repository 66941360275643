import * as OrderActions from '../actions/OrderActions';

const OrderReducer = (
    order = {
        isSaving: false,
        isLoading: false,
        error: false,
        data: {
            items: [],
            type: 'T'
        }
    }, 
    action
    ) => {
    
    switch(action.type){
        case OrderActions.ORDER_SET_ORDER:
            return {
                ...order,
                data: {
                    ...action.order
                }
            }
        
        case OrderActions.ORDER_CONFIRM:
            return {
                ...order,
                isSaving: true,
                error: false
            }

        case OrderActions.ORDER_CONFIRM_RESPONSE:
            return {
                ...order,
                isSaving: false,
                error:false,
                data: {
                    ...order.data,
                    id: action.order.inserted_id
                }
            }

        case OrderActions.ORDER_CONFIRM_ERROR:
            return {
                ...order,
                isSaving: false,
                error: true
            }

        case OrderActions.ORDER_SET_TABLE:
            return {
                ...order,
                data: {
                    ...order.data,
                    type: "M",
                    table_number: parseInt(action.table)
                }
            }
        default: return order;
    }
}

export default OrderReducer;
