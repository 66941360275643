import db from "./db"


const auth = {
    setToken: (tk) => {
        db.setObject('token', tk);
    },
    setUser: (us) => {
        db.setObject('user',us);
    },
    getToken: () => {
        return db.getObject('token');
    },
    getUser: () => {
        return db.getObject('user');;
    },
    logout: () => {
        db.setObject('token', null);
        db.setObject('user', null);
    }
}

auth.checkPermission = (pr) => {
    let user = auth.getUser();

    if(!user) return false;

    if(user.perfil === 'SU') return true;

    if(Array.isArray(pr)){
        let hasPermission = true;

        pr.forEach(checkPr => {
            if(!user.permissions.includes(checkPr)) hasPermission = false;
        });

        return hasPermission;
    }

    else return auth.getUser().permissions.includes(pr);
}

auth.checkAnyPermission = (pr) => {
    let user = auth.getUser();

    if(!user) return false;

    if(user.perfil === 'SU') return true;

    let hasPermission = false;

    pr.forEach(checkPr => {
        if(user.permissions.includes(checkPr)) hasPermission = true;
    });

    return hasPermission;
}

export default auth;