const utils = {
    status: {
		"0": "Rascunho",
		"1": "Preparando",
		"2": "Entregue",
		"3": "Aguardando pagamento",
		"4": "Finalizado",
    },
    
    number_format: (number, decimals, dec_point, thousands_sep) => {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function (n, prec) {
                var k = Math.pow(10, prec);
                return '' + Math.round(n * k) / k;
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '').length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1).join('0');
        }
        return s.join(dec);
    },

    getTotal: (items) => {
        let total = 0.0;

        items.forEach(item => {
            if(!item.data.date_canceled){
                total = total + (parseFloat(item.data.price) * parseInt(item.data.qnt));
    
                if(item.data.optional && item.data.optional.length > 0){
                    item.data.optional.forEach(opt => {
                        total = total + (parseFloat(opt.data.price) * parseInt(item.data.qnt));
                    })
                }
            }
        });
        return utils.number_format(total, 2,',','');
    },
    
    getTotalFloat: (items) => {
        let total = 0.0;

        items.forEach(item => {
            if(!item.data.date_canceled){
                total = total + (parseFloat(item.data.price) * parseInt(item.data.qnt));
    
                if(item.data.optional && item.data.optional.length > 0){
                    item.data.optional.forEach(opt => {
                        total = total + (parseFloat(opt.data.price) * parseInt(item.data.qnt));
                    })
                }
            }
        });
        return total
    },

    getTotalPaid: (payments) => {
        let total = 0.0;

        payments.forEach(pay => {
            total = total + parseFloat(pay.data.value)
        });

        return total;
    },

    getQntItems: (items) => {
        let total = 0;

        items.forEach(item => {
            if(!item.data.date_canceled) total = total + item.data.qnt;
        });
        return total;
    },

    currencyToFloat: (currency) => {
        currency = !isNaN(currency) ? utils.number_format(currency,2,',','') : currency;

        return Number(currency.toString().replace(/[.]+/g,'').replace(',','.').replace(/[^0-9.]+/g,""))
    }
}

export default utils;