import * as SettingsActions from '../actions/SettingsActions';

const SettingsReducer = (
    settings = {
        payments: {
            data: [],
            error: null,
            isLoading: false
        },
        styles: {
            data: {
                primaryColor:           '#2a2e3b', //#057302
                accentColor:            '#FFFFFF',
    
                primaryTextColor:       '#CCCCCC', //#FFFFFF
                secondTextColor:        '#CCCCCC',
                accentTextColor:        '#003557',
    
                detailAreaColor:        '#254961',
                detailTextColor:        '#FFFFFF',
                backgroundColor:        '#003557',
                logo:                   null
            },
            isLoading: false,
            error: null
        },
        delivery: {
            isLoading: false,
            error: null,
            data: []
        },
        settings: {},
        features: {
            isLoading: false,
            data: {}
        }
    }, 
    action
    ) => {
    
    switch(action.type){
        case SettingsActions.SETTINGS_PAYMENTS_LIST:
            return {
                ...settings,
                payments: {
                    ...settings.payments,
                    isLoading: true,
                    error: null
                }
            };
        
        case SettingsActions.SETTINGS_PAYMENTS_LIST_RESPONSE:
            return {
                ...settings,
                payments: {
                    ...settings.payments,
                    isLoading: false,
                    error: null,
                    data: [...action.paymentsList.data]
                }
            };

        case SettingsActions.SETTINGS_PAYMENTS_ERROR:
            return {
                ...settings,
                payments: {
                    ...settings.payments,
                    isLoading: false,
                    error: action.error
                }
            };
        
        case SettingsActions.SETTINGS_STYLES_LIST:
            return {
                ...settings,
                styles: {
                    ...settings.styles,
                    isLoading: true,
                    error: null
                }
            };
        
        case SettingsActions.SETTINGS_STYLES_LIST_RESPONSE:
            return {
                ...settings,
                styles: {
                    ...settings.styles,
                    data: {
                        ...action.styles
                    },
                    isLoading: false,
                    error: null
                }
            };

        case SettingsActions.SETTINGS_STYLES_ERROR:
            return {
                ...settings,
                styles: {
                    ...settings.styles,
                    error: action.error,
                    isLoading: false
                }
            };
        
        case SettingsActions.SETTINGS_LIST_RESPONSE:
            return {
                ...settings,
                settings: action.settings.data
            };
        
        case SettingsActions.SETTINGS_DELIVERY:
            return {
                ...settings,
                delivery: {
                    ...settings.delivery,
                    error: null,
                    isLoading: true
                }
            };
        case SettingsActions.SETTINGS_DELIVERY_RESPONSE:
            return {
                ...settings,
                delivery: {
                    ...settings.delivery,
                    isLoading: false,
                    error: null,
                    data: [
                        ...action.delivery.data
                    ]
                }
            };
        case SettingsActions.SETTINGS_DELIVERY_ERROR:
            return {
                ...settings,
                delivery: {
                    ...settings.delivery,
                    isLoading: false,
                    error: action.error
                }
            };

        case SettingsActions.SETTINGS_FEATURES_REQUEST:
            return {
                ...settings,
                features: {
                    ...settings.features,
                    isLoading: true
                }
            }

        case SettingsActions.SETTINGS_FEATURES_SUCCESS:
            return {
                ...settings,
                features: {
                    ...settings.features,
                    isLoading: false,
                    data: action.features
                }
            }

        case SettingsActions.SETTINGS_FEATURES_FAILURE:
            return {
                ...settings,
                features: {
                    ...settings.features,
                    isLoading: false,
                    data: {}
                }
            }
        default: return settings;
    }
}

export default SettingsReducer;
