import React, { useState, useEffect, useContext} from 'react';
import { Button, SecondaryButton,  MiniContainer, SecondaryText, Container, RouterLink, CloseWarning } from '../../assets/GlobalStyle';
import { Link } from 'react-router-dom';
import {SelectWrapper, TextInput} from './styles'
import { connect, useDispatch } from 'react-redux';
import * as OrderActions from '../../data/actions/OrderActions'
import * as SettingsActions from '../../data/actions/SettingsActions'
import utils from '../../utils'
import Loading from '../../Components/Loading';
import { ThemeContext } from 'styled-components';

function Address(props) {
    const {slug = '', order_store = {}, delivery_store = []} = props;

    const delivery = delivery_store.data || [];
    
    const theme = useContext(ThemeContext);

    const order = order_store.data;

    const dispatch = useDispatch();

    const [step, setStep] = useState(0)

    const next_step = () => {
        if(step < 6) setStep(step + 1);
    }

    const prev_step = () => {
        if(step > 0) setStep(step - 1);
    }

    useEffect(() => {
        dispatch(OrderActions.load_order(slug))
        dispatch(SettingsActions.deliveryCharges(slug))
    }, [dispatch, slug])

    const handle_delivery_city = id_city => {
        let [city] = delivery.filter(deli => deli.id === id_city)
        if(city){
            dispatch(OrderActions.set_order({
                store: slug,
                order: {
                    ...order,
                    id_city: city.id,
                    id_neighborhood: null
                }
            }))
        }
    }

    const handle_delivery_neighborhood = neigh_id => {
        let [neighborhood] = selectedCity.neighborhoods.filter(neigh => neigh.id === neigh_id)
        if(neighborhood){
            dispatch(OrderActions.set_order({
                store: slug,
                order: {
                    ...order,
                    id_neighborhood: neighborhood.id
                }
            }))
        }
    }

    const [selectedCity] = order.id_city ? delivery.filter(deli => order.id_city && order.id_city === deli.id) : (delivery.length > 0 ? [delivery[0]] : [undefined]);
    
    const [selectedNeighborhood] = selectedCity && order.id_neighborhood ? selectedCity.neighborhoods.filter(deli => order.id_neighborhood && order.id_neighborhood === deli.id) : (selectedCity && selectedCity.neighborhoods.length > 0 ? [selectedCity.neighborhoods[0]] : [undefined]);
    
    const validate_step = () => {
        if(step === 0) {
            if(!order.id_city && selectedCity && selectedCity.id) dispatch(OrderActions.set_order({
                store: slug,
                order: {
                    ...order,
                    id_city: selectedCity.id
                }
            }))
        }

        if(step === 1) {
            if(!order.id_neighborhood && selectedNeighborhood && selectedNeighborhood.id) dispatch(OrderActions.set_order({
                store: slug,
                order: {
                    ...order,
                    id_neighborhood: selectedNeighborhood.id
                }
            }))
        }

        if(step === 2) {
            return order.address && order.address !== ''
        }
        if(step === 3) {
            return order.number && order.number !== ''
        }
        else
        return true
    }

    const isLoading = delivery_store.isLoading;

    const error = delivery_store.error || null;

    return (
        <React.Fragment>
            {theme.is_opened !== undefined && !theme.is_opened && <CloseWarning>ESTAMOS FECHADOS</CloseWarning>}

            <Container className={'vertical-align center'}>
                {isLoading && <MiniContainer>
                    <Loading color={theme.SecondaryTextColor} />
                </MiniContainer>}
                
                {error && !isLoading && <MiniContainer>
                    <SecondaryText>Algo deu errado... :/</SecondaryText>
                    <SecondaryText>Não conseguimos encontrar a lista de endereços onde o estabelecimento atende...</SecondaryText>
                    <RouterLink>
                        {/* eslint-disable-next-line*/}
                        <a name="error_products">
                            <Button onClick={() => {
                                dispatch(OrderActions.load_order(slug))
                                dispatch(SettingsActions.deliveryCharges(slug))
                            }}>Tentar novamente</Button>
                        </a>
                    </RouterLink>
                </MiniContainer>}
                
                {!error && !isLoading && <MiniContainer>
                    {step === 0 && <React.Fragment>
                        <SecondaryText>Ótimo! Conta para nós onde você mora, que entregamos pra você o mais rápido possível!</SecondaryText>
                        <SecondaryText>Qual sua cidade?</SecondaryText>
                        <SelectWrapper>
                            <select onChange={(e) => {
                                handle_delivery_city(parseInt(e.target.value))
                            }} value={selectedCity ? selectedCity.id : ''}>
                                {delivery.filter(deli => deli.status).map(deli => <option value={deli.id} key={`delivery-city-id-${deli.id}`}>{deli.name}</option>)}
                            </select>
                        </SelectWrapper>
                    </React.Fragment>}
                    

                    {step === 1 && selectedCity && selectedCity.neighborhoods && selectedCity.neighborhoods.length > 0 && <React.Fragment>
                        
                        <SecondaryText>E seu bairro?</SecondaryText>

                        <SelectWrapper>
                            <select onChange={(e) => {
                                handle_delivery_neighborhood(parseInt(e.target.value))
                            }} value={selectedNeighborhood ? selectedNeighborhood.id : ''}>
                                {selectedCity.neighborhoods.filter(neigh => neigh.status).map(deli => <option value={deli.id} key={`delivery-neigh-id-${deli.id}`}>{deli.name}</option>)}
                            </select>
                        </SelectWrapper>
                    </React.Fragment>}

                    {step === 2 && <React.Fragment>
                        <SecondaryText>Endereço:</SecondaryText>
                        <TextInput
                        style={{width: '350px'}}
                            onChange={(e) => {
                                dispatch(OrderActions.set_order({
                                    store: slug,
                                    order: {
                                        ...order,
                                        address: e.target.value
                                    }
                                }))
                            }}
                            value={order.address ? order.address : ''}
                        />
                    </React.Fragment>}

                    {step === 3 && <React.Fragment>
                        <SecondaryText>Número:</SecondaryText>
                        <TextInput
                            onChange={(e) => {
                                dispatch(OrderActions.set_order({
                                    store: slug,
                                    order: {
                                        ...order,
                                        number: e.target.value
                                    }
                                }))
                            }}
                            value={order.number ? order.number : ''}
                        />
                    </React.Fragment>}

                    {step === 4 && <React.Fragment>
                        <SecondaryText>CEP: (opcional)</SecondaryText>
                        <TextInput
                            onChange={(e) => {
                                dispatch(OrderActions.set_order({
                                    store: slug,
                                    order: {
                                        ...order,
                                        zipcode: e.target.value
                                    }
                                }))
                            }}
                            value={order.zipcode ? order.zipcode : ''}
                        />
                    </React.Fragment>}

                    {step === 5 && <React.Fragment>
                        <SecondaryText>Complemento:  (opcional)</SecondaryText>
                        <TextInput
                            style={{width: '350px'}}
                            onChange={(e) => {
                                dispatch(OrderActions.set_order({
                                    store: slug,
                                    order: {
                                        ...order,
                                        complement: e.target.value
                                    }
                                }))
                            }}
                            value={order.complement ? order.complement : ''}
                        />
                    </React.Fragment>}
                    
                    {step === 6 && <React.Fragment>
                        <SecondaryText>Endereço de entrega:</SecondaryText>
                        <SecondaryText>{`${order.address}, ${order.number},${order.complement ? ` ${order.complement},` : ''}${order.zipcode ? ` ${order.zipcode},` : ''} ${selectedNeighborhood.name}, ${selectedCity.name}`}</SecondaryText>
                        <SecondaryText style={{marginTop: 30}}>Taxa de entrega: R$ {utils.number_format(selectedNeighborhood.price,2,',','')}</SecondaryText>    
                    </React.Fragment>}

                </MiniContainer>}
                

                {!error && !isLoading && <React.Fragment>
                    {step === 6 && <RouterLink>
                        {// eslint-disable-next-line
                            <a href="javascript:void(0);">
                            <SecondaryButton onClick={() => prev_step()}>VOLTAR</SecondaryButton>
                        </a>}
                        <Link to="/pagamento">
                            <Button>CONTINUAR</Button>
                        </Link>
                    </RouterLink>}

                    {step < 6 && <RouterLink>
                        {step === 0 && <Link to={'/receber'}>
                            <SecondaryButton>VOLTAR</SecondaryButton>
                        </Link>}

                        {// eslint-disable-next-line
                        step > 0 && <a href="javascript:void(0);">
                            <SecondaryButton onClick={() => prev_step()}>VOLTAR</SecondaryButton>
                        </a>}
                        
                        {// eslint-disable-next-line
                            <a href="javascript:void(0);">
                            <Button onClick={() => next_step()} disabled={!validate_step()}>CONTINUAR</Button>
                        </a>}
                    </RouterLink>}
                </React.Fragment>}
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return ({
        order_store: state.order,
        payments: state.settings.payments,
        delivery_store: state.settings.delivery
    })
}

export default connect(mapStateToProps)(Address);