import * as OrdersActions from '../actions/OrderActions';
import {OrderService} from '../services/OrderService'

import { all, put, takeLatest } from 'redux-saga/effects';
import db from '../services/db';
import {toastr} from 'react-redux-toastr'

//SET
function* setOrder(action){
    let tmp_order = db.getObject('order')
    
    let order = {
        ...tmp_order,
        [action.store]: {
            ...action.order
        }
    }
    db.setObject('order', order);

    
    yield put(OrdersActions.set_order_response(action.order))
}
function* watchSetOrder(){
    yield takeLatest(OrdersActions.ORDER_SET_ORDER, setOrder);
}

// LOAD
function* loadOrder(action){
    let data = db.getObject('order');

    if(data && data[action.store] !== undefined && data[action.store] !== ''){
        data = data[action.store];
    }

    else data = {
        items: []
    }

    if(data) yield put(OrdersActions.set_order({
        store: action.store,
        order: data
    }))
}
function* watchLoadOrder(){
    yield takeLatest(OrdersActions.ORDER_LOAD_ORDER, loadOrder);
}

// CONFIRM
function* confirmOrder(action){
    let data = null;
    let error = null;

    yield OrderService.confirmOrder(action.order, action.store)
    .then(res_confirm => {
        data = res_confirm.data;
        
        if(data.status){
            data = data.data;
        }
        else if(data.msg) {
            toastr.error('Erro...', data.msg)
            data = null;
        }
        else {
            toastr.error('Erro...', 'Houve um erro inexperado ao concluir seu pedido')
            data = null;
        }
    })
    .catch(err => {
        if(err && err.response && err.response.data && err.response.data.message) 
            toastr.error('Erro...', err.response.data.message)
        error = err;
    })
    
    if(data) yield put(OrdersActions.order_confirm_response(data))
    if(error) {
        
        yield put(OrdersActions.order_confirm_error())
    }
}
function* watchconfirmOrder(){
    yield takeLatest(OrdersActions.ORDER_CONFIRM, confirmOrder);
}


export default function* ProductsSaga(){
    yield all([
        watchSetOrder(),
        watchLoadOrder(),
        watchconfirmOrder()
    ])
}