import React, { useState, useContext } from 'react'
import {ProductConfigOverlay, ProductConfigWrapper, Content, ActionsWrapper, ButtonGroup, Thumb, QntWrapper, Option} from './styles'
import { baseURL } from '../../data/services/api';
import utils from '../../utils';
import { ThemeContext } from 'styled-components';
import {toastr} from 'react-redux-toastr'

export default function ProductConfig(props) {
    const { onCancel = () => {}, product = {}, onAdd = () => {} } = props;

    const [qnt, setQnt] = useState(1);
    const [orderOptionals, setOrderOptionals] = useState([]);

    const theme = useContext(ThemeContext);

    const handleQnt = (num) => {
        let tmpQnt = qnt;

        tmpQnt = tmpQnt + num;

        tmpQnt = tmpQnt <= 0 ? 1 : tmpQnt;

        setQnt(tmpQnt);
    }

    const handle_select = (opt) => {
        let [category] = product.optionals.filter(filtered => filtered.id === opt.id_category);

        let total_selected = get_selecteds(category.id);


        let count = orderOptionals.filter(filtered => filtered.id_optional === opt.id).length;

        if(count === 0){
            if(total_selected < category.max || category.max === 0) {
                setOrderOptionals([...orderOptionals, {
                    id_optional         : opt.id,
                    id_category         : category.id,
                    price               : opt.price,
                    multiple            : category.multiple,
                    name_category       : category.name,
                    name                : opt.name
                }])
            }
            else if(category.max === 1){
                let new_selecteds = orderOptionals.filter(opts => opts.id_category !== category.id);

                setOrderOptionals([...new_selecteds, {
                    id_optional         : opt.id,
                    id_category         : category.id,
                    price               : opt.price,
                    multiple            : category.multiple,
                    name_category       : category.name,
                    name                : opt.name
                }])
            }

        }
        else {
            let new_selecteds = orderOptionals.filter(opts => opts.id_optional !== opt.id);

            setOrderOptionals(new_selecteds)
        }
    }

    const item_price = () => {
        let total = product.price;

        orderOptionals.forEach((ele, idx) => {
            total = parseFloat(total) + parseFloat(ele.price);
        })
        
        total = total * qnt;

        return total;
    }

    const add_item = () => {
        const new_item = {
            data: {
                qnt,
                name            : product.name,
                price           : product.price,
                id_product      : product.id,
                optional        : []
            }
        }


        for(let key in orderOptionals){
            let opt_item = {
                data: {
                    ...orderOptionals[key],
                    id_category_bkp: orderOptionals[key].id_category
                }
            }

            opt_item.data.id_category = orderOptionals[key].id_category;

            new_item.data.optional.push(opt_item);
        }

        onAdd(new_item);
    }

    const get_selecteds = id_category => {
        let total = orderOptionals.filter(opt => opt.id_category === id_category).length;


        return total;
    }

    return (
        <ProductConfigOverlay>
            <ProductConfigWrapper>
                <Content>
                    <div className={'row'}>
                        <Thumb src={product.thumb ? `${baseURL}/media/products/${product.thumb}` : `${baseURL}/media/settings/${theme.logo}`} />
                        <div className={'col'}>
                            <h2>{product.name}</h2>
                            <p>
                            {product.ingredients}
                            </p>
                        </div>
                    </div>

                    <div className={'col'}>
                        {product.optionals.filter(opt => opt.status).map(opt => <div key={`prod-opt-${opt.id}`} className={'text-center mb-4'}>
                            <h3>{opt.name}</h3>
                            
                            {opt.min > 0 && <h6>Obrigatório: {get_selecteds(opt.id) > opt.min ? opt.min : get_selecteds(opt.id)}/{opt.min}</h6>}
                            {opt.max > 0 && <h6>Máximo: {get_selecteds(opt.id)}/{opt.max}</h6>}

                            {opt.optional.filter(opts => opts.status).map(opts => 
                            <Option 
                                onClick={() => handle_select(opts)} 
                                key={`prod-opt-${opt.id}-${opts.id}`} 
                                className={orderOptionals.filter(searchOpt => searchOpt.id_optional === opts.id).length > 0 ? 'selected' : ''}
                                >{opts.name}{opts.price > 0 ? ` + R$${utils.number_format(opts.price,2,',','')}` : ``}</Option>)}
                        </div>)}
                        
                        <div>
                            <h3>Quantidade</h3>

                            <QntWrapper>
                                <button onClick={() => handleQnt(-1)} disabled={qnt <= 1}>-</button>
                                <span>{qnt}</span>
                                <button onClick={() => handleQnt(1)}>+</button>
                            </QntWrapper>
                        </div>
                    </div>
                    
                </Content>
                <ActionsWrapper>
                    <ButtonGroup>
                        <button onClick={() => onCancel()} className={'secondary'}>CANCELAR</button>
                        <button onClick={() => add_item()} className={'primary'}>ADICIONAR POR R${utils.number_format(item_price(),2,',','')}</button>
                    </ButtonGroup>
                </ActionsWrapper>
            </ProductConfigWrapper>
        </ProductConfigOverlay>
        
    )
}
