export const ORDER_LIST_ORDER                   = "ORDER_LIST_ORDER";
export const ORDER_SET_ORDER                    = "ORDER_SET_ORDER";
export const ORDER_SET_ORDER_RESPONSE           = "ORDER_SET_ORDER_RESPONSE";
export const ORDER_LOAD_ORDER                   = "ORDER_LOAD_ORDER";
export const ORDER_LOAD_ORDER_RESPONSE          = "ORDER_LOAD_ORDER_RESPONSE";
export const ORDER_CONFIRM                      = "ORDER_CONFIRM";
export const ORDER_CONFIRM_RESPONSE             = "ORDER_CONFIRM_RESPONSE";
export const ORDER_CONFIRM_ERROR                = "ORDER_CONFIRM_ERROR";
export const ORDER_SET_TABLE                    = "ORDER_SET_TABLE";

export const list = () => {
    return {
        type: ORDER_LIST_ORDER
    }
}

export const set_order = (data) => {
    return {
        type: ORDER_SET_ORDER,
        order: data.order,
        store: data.store
    }
}

export const set_order_response = (order) => {
    return {
        type: ORDER_SET_ORDER_RESPONSE,
        order
    }
}

export const load_order = (store) => {
    return {
        type: ORDER_LOAD_ORDER,
        store
    }
}

export const order_confirm = (order, store) => {
    return {
        type: ORDER_CONFIRM,
        order,
        store
    }
}

export const order_confirm_response = (order) => {
    return {
        type: ORDER_CONFIRM_RESPONSE,
        order
    }
}

export const order_confirm_error = () => {
    return {
        type: ORDER_CONFIRM_ERROR
    }
}

export const order_set_table = (table) => {
    return {
        type: ORDER_SET_TABLE,
        table
    }
}