import * as SettingsActions from '../actions/SettingsActions';
import {SettingsService} from '../services/SettingsService';

import { all, put, takeLatest } from 'redux-saga/effects';

import {toastr} from 'react-redux-toastr'

//SETTINGS
function* settings(action){
    let data = [];
    yield SettingsService.getSettings().then(
        result => {
            data = result.data
        }
    )
    .catch(err => {
        if(err.response && err.response.data){
            let data = err.response.data;

            if(action.onError){
                if(data.msg) {
                    action.onError(data.msg)
                }
                else action.onError('Não foi posível carregar configurações');
            }
        }
    });
    
    yield put(SettingsActions.settingsResponse(data))
}
function* watchSettings(){
    yield takeLatest(SettingsActions.SETTINGS_LIST, settings);
}

//PAYMENTS
function* getPayments(action){
    let data = null;
    let error = null;
    yield SettingsService.getPayments(action.store).then(
        result => {
            data = result.data
        }
    )
    .catch(err => {
        if(err.response && err.response.data){
            error = err.response.data;
        }
    });
    
    if(data) yield put(SettingsActions.paymentsListResponse(data));
    if(error) yield put(SettingsActions.paymentsError(error));
}
function* watchListPayments(){
    yield takeLatest(SettingsActions.SETTINGS_PAYMENTS_LIST, getPayments);
}

//DELIVERY
function* deliveryCharges(action){
    let data = null;
    let error = null;
    yield SettingsService.getDeliveryCharges(action.store).then(
        result => {
            data = result.data
        }
    )
    .catch(err => {
        if(err.response && err.response.data){
            error = err.response.data;
        }
    });
    
    if(data) yield put(SettingsActions.deliveryChargesResponse(data));
    if(error) yield put(SettingsActions.deliveryChargesError(error));
}
function* watchDeliveryCharges(){
    yield takeLatest(SettingsActions.SETTINGS_DELIVERY, deliveryCharges);
}

// STYLES
function* getStyles(action){
    let data = null;
    let error = null;
    yield SettingsService.getStyles(action.slug ? action.slug : '').then(
        result => {
            data = result.data.data;
        }
    )
    .catch(err => {
        if(err.response && err.response.data){
            error = err.response.data;
        }
    });
    
    if(data) yield put(SettingsActions.stylesResponse({
        ...data,
        secondTextColor: data.secondaryTextColor
    }));
    if(error) yield put(SettingsActions.stylesError(error));
}
function* watchGetStyles(){
    yield takeLatest(SettingsActions.SETTINGS_STYLES_LIST, getStyles);
}

//Features
function* sagaFeatures(action){
    let data = null;
    yield SettingsService.serviceFeatures(action.slug).then(
        result => {
            let response = result.data;

            if(response.status) {
                data = response.data
            }

            else toastr.warning('Algo deu errado...', response.message)
        }
    )
    .catch(err => {
        console.log('error teste', err);
        toastr.warning('Algo deu errado...','mensagem de erro')

        if(err.response && err.response.data){
        }
    });
    
    if(data) yield put(SettingsActions.actionSettingsFeaturesSuccess(data));
}
function* watchSagaFeatures(){
    yield takeLatest(SettingsActions.SETTINGS_FEATURES_REQUEST, sagaFeatures);
}

export default function* SettingsSaga(){
    yield all([
        watchListPayments(),
        watchGetStyles(),
        watchSettings(),
        watchDeliveryCharges(),
        watchSagaFeatures()
    ])
}