export const CUSTOMERS_LIST = "CUSTOMERS_LIST";

export const list_customers = (filter = {}) => {
    return {
        type: CUSTOMERS_LIST,
        filter
    }
}

export const CUSTOMERS_LIST_RESPONSE = "CUSTOMERS_LIST_RESPONSE";

export const list_customers_response = (customers = []) => {
    return {
        type: CUSTOMERS_LIST_RESPONSE,
        customers
    }
}

export const CUSTOMERS_LIST_ERROR = "CUSTOMERS_LIST_ERROR";

export const list_customers_error = (error) => {
    return {
        type: CUSTOMERS_LIST_ERROR,
        error
    }
}