import styled from "styled-components";
import media from 'styled-media-query'
import { breakPoints, themeColor } from "../../assets/GlobalStyle";


export const ProductConfigOverlay = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.75);
    position: absolute;
    top:0;
    left:0;
    /* display: flex;
    justify-content: center; */
    z-index: 11;
    overflow-y: auto;
`

export const ProductConfigWrapper = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: ${props => themeColor(props, 'backgroundColor')};
    overflow-y: auto;
    display: flex;  
    flex-direction: column;

    ${media.greaterThan(breakPoints.xs)`
        width: 90%;
        max-width: 600px;
        margin: 20px auto;
        height: auto;
        min-height: 300px;
        border-radius: 5px;
        overflow-y:hidden;
    `}
`

export const Content = styled.div`
    padding: 20px;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;

    .row {
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0;
    }

    .col {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
    }

    h2, h3, h6 {
        color: ${props => themeColor(props, 'secondTextColor')};
        font-weight: normal;
        /* width: auto; */
        flex: 1 1 auto;
        font-size: 20px;
    }

    h3 {
        color: ${props => themeColor(props, 'accentColor')};
        font-size: 18px;
        text-align: center;
    }

    h6 {
        color: ${props => themeColor(props, 'accentColor')};
        font-size: 12px;
        text-align: center;
    }

    p {
        color: ${props => themeColor(props, 'secondTextColor')};

    }

    ${media.greaterThan(breakPoints.xs)`
        overflow-y: hidden;
    `}
`

export const Option = styled.button`
    padding: 3px 10px;
    border-radius: 20px;
    border: 1px solid ${props => themeColor(props, 'secondTextColor')};
    color: ${props => themeColor(props, 'secondTextColor')};
    background-color: ${props => themeColor(props, 'backgroundColor')};
    display: inline-block;
    font-size: 14px;
    margin: 3px;
    font-weight: bold;
    text-transform: uppercase;

    &.selected {
        color: ${props => themeColor(props, 'backgroundColor')};
        background-color: ${props => themeColor(props, 'secondTextColor')};    
        border: 1px solid ${props => themeColor(props, 'secondTextColor')};
    }
`

export const ActionsWrapper = styled.div`
    width: 100%;
    height: 72px;
    display: block;
    background-color: #0F0;
`

export const ButtonGroup = styled.div`
    width: 100%;
    height: 100%;
    background-color: #00f;
    display: flex;
    justify-content: center;  
    border-top: 1px solid ${props => themeColor(props, 'accentColor')};

    button{
        margin: 0;
        padding: 0;
        border: none;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;

        &.primary{
            color: ${props => themeColor(props, 'accentTextColor')};
            background-color: ${props => themeColor(props, 'accentColor')};
        }

        &.secondary{
            color: ${props => themeColor(props, 'accentColor')};
            background-color: ${props => themeColor(props, 'accentTextColor')};
        }
    }
`

export const Thumb = styled.img`
    width: 96px;
    height: 96px; 
    border-radius: 64px;
    border: 4px solid ${props => themeColor(props, 'secondTextColor')};
    margin-right:20px;
`

export const QntWrapper = styled.div`
    height: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;

    button {
        width: 50px;
        height: 50px;
        color: ${props => themeColor(props, 'accentTextColor')};
        background-color: ${props => themeColor(props, 'accentColor')};
        border-radius: 30px;
        border:none;
        font-size: 20px;

        &:disabled{
            opacity: 0.5
        }
    }

    span {
        width: 30px;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: ${props => themeColor(props, 'secondTextColor')};
        font-size: 20px;
    }
`