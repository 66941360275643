import React, { useEffect, useContext } from 'react';
import { Button, SecondaryButton,  MiniContainer, SecondaryText, Container, RouterLink, CloseWarning } from '../../assets/GlobalStyle';
import { Link } from 'react-router-dom';
import {TextInput} from './styles';
import { useDispatch, connect } from 'react-redux';
import * as OrderActions from '../../data/actions/OrderActions'
import { ThemeContext } from 'styled-components';

function Observations(props) {
    const { order_store = {}, slug = ''} = props;

    const order = order_store.data;

    const theme = useContext(ThemeContext);

    const dispatch = useDispatch();

    const handleChange = (e) => {
        dispatch(OrderActions.set_order({
            store: slug,
            order: {
                ...order,
                obs: e.target.value.substring(0,200)
            }
        }))
    }

    useEffect(() => {
        dispatch(OrderActions.load_order(slug))
    }, [dispatch])

    return (
        <React.Fragment>
            {theme.is_opened !== undefined && !theme.is_opened && <CloseWarning>ESTAMOS FECHADOS</CloseWarning>}

            <Container className={'vertical-align center'}>
                
                <MiniContainer style={{alignItems: 'center'}}>
                    <SecondaryText>Deseja adicionar alguma observação ao seu pedido? A hora é agora!</SecondaryText>
                    <TextInput
                        onChange={handleChange}
                        value={order.obs ? order.obs.substring(0,200) : ''}
                    ></TextInput>
                    <SecondaryText>{order.obs ? order.obs.length : 0}/200 caracteres utilizados</SecondaryText>
                </MiniContainer>
                
            
                <RouterLink>
                    <Link to={'/pedido'}>
                        <SecondaryButton>VOLTAR</SecondaryButton>
                    </Link>
                    <Link to="/identificacao">
                        <Button>CONTINUAR</Button>
                    </Link>
                   
                </RouterLink>
            
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
  order_store: state.order
});

export default connect(mapStateToProps)(Observations);