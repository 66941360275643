import React from 'react'
import { NavWrapper } from './styles'


export default function CategoriesNav(props) {
    const {refs, anchors, categories = []} = props;
    return (
        <NavWrapper>
            {categories.map((cat,idx) => <li key={cat.id} ref={el => refs.current[idx] = el} onClick={() => {
                anchors.current[idx].scrollIntoView({block: "start", behavior: "smooth"})
            }}>
                <span>{cat.name}</span>
            </li>)}
        </NavWrapper>
    )
}
