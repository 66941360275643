import React, { useEffect, useContext } from 'react';
import { Button, SecondaryButton,  MiniContainer, SecondaryText, Container, RouterLink, CloseWarning } from '../../assets/GlobalStyle';
import { Link } from 'react-router-dom';
import {TextInput} from './styles';
import { useDispatch, connect } from 'react-redux';
import * as OrderActions from '../../data/actions/OrderActions'
import { ThemeContext } from 'styled-components';

function CustomerName(props) {
    const { order_store = {}, features = {}, slug = ''} = props;

    const order = order_store.data;

    const theme = useContext(ThemeContext);

    const dispatch = useDispatch();

    const handleChange = (e) => {
        dispatch(OrderActions.set_order({
            store: slug,
            order: {
                ...order,
                customer_name: e.target.value
            }
        }))
    }

    useEffect(() => {
        dispatch(OrderActions.load_order(slug))
    }, [dispatch])

    return (
        <React.Fragment>
            {theme.is_opened !== undefined && !theme.is_opened && <CloseWarning>ESTAMOS FECHADOS</CloseWarning>}

            <Container className={'vertical-align center'}>
                
                <MiniContainer style={{alignItems: 'center'}}>
                    <SecondaryText>Como gostaria de ser chamado?</SecondaryText>
                    <TextInput
                        onChange={handleChange}
                        value={order.customer_name ? order.customer_name : ''}
                    />
                </MiniContainer>
                
            
                <RouterLink>
                    {features.data.observations_feature && <Link to={'/observacoes'}>
                        <SecondaryButton>VOLTAR</SecondaryButton>
                    </Link>}

                    {!features.data.observations_feature && <Link to={'/pedido'}>
                        <SecondaryButton>VOLTAR</SecondaryButton>
                    </Link>}

                    {order.customer_name && <Link to="/telefone">
                        <Button>CONTINUAR</Button>
                    </Link>}

                    {// eslint-disable-next-line
                    !order.customer_name && <a href="javascript:void(0);">
                        <Button disabled onClick={() => alert('Precisamos saber seu nome')}>CONTINUAR</Button>
                    </a>}
                    
                </RouterLink>
            
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
  order_store: state.order,
  features: state.settings.features
});

export default connect(mapStateToProps)(CustomerName);