import styled from "styled-components";
import { themeColor, breakPoints } from "../../assets/GlobalStyle";
import media from "styled-media-query";


export const NavWrapper = styled.ul`
    padding: 0;
    margin:0;
    width: 100%;
    height: 72px;
    background-color: ${props => themeColor(props, 'accentColor')};
    color: ${props => themeColor(props, 'accentTextColor')};
    box-sizing: border-box;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    ::-webkit-scrollbar {
        height: 0px;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }

    ${media.greaterThan(breakPoints.lg)`
        width: 70%;
    `}

    li {
        display: inline-flex;
        height: 100%;
        align-items:center;
        list-style: none;
        padding: 0 15px;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;

        &.active, &:hover{
            &:after{
                width: 0;
                height: 0;
                border: 20px solid transparent;
                border-bottom: solid 10px ${props => themeColor(props, 'backgroundColor')};
                position: absolute;
                top: 100%;
                margin-top:-30px;
                left:50%;
                margin-left: -20px;
                content: '';
                
            }
        }

        &:hover{
            &:after{
                opacity: 0.6;
                
            }
        }
    }
`;
